if (process.env.NODE_ENV !== 'production') {
  require('preact/debug');
} else {
  require('preact/devtools');
}

import queryString from 'qs';
import React from 'react';
import { render } from 'react-dom';
import fetch from 'unfetch';

// Webpack public path module must be imported first
import '../../publicpath.js';
import STORE from '../../store';
import TradeView from '../../views/trade/index.jsx';

import '../../styles.js';

function load() {
  fetch(`${__config.api_path}models`)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      init(data);
    });
}

function init(rawModelsData) {
  STORE.init(rawModelsData, queryString.parse(window.location.search.replace(/^\?/, '')));
  render(<TradeView store={STORE} />, document.getElementById('root'));
}

if (document.readyState !== 'loading') {
  load();
} else {
  document.addEventListener('DOMContentLoaded', load);
}
