import React from 'react';

import './styles.scss';

export default function InfoModal({ header, children }) {
  return (
    <div className="info-modal">
      <div className="info-modal__header">{header}</div>
      <div className="info-modal__body">{children}</div>
    </div>
  );
}
