import React, { Component } from 'react';
import Bezier from 'bezier-js';
import { cubify } from 'points';
import Vector from 'victor';

function bezierToString (points, first) {
    let commands = [];

    if (first) {
        commands.push('M', points[0].x, points[0].y);
    }

    switch (points.length) {
        case 3:
            commands.push('Q', points[1].x, points[1].y, points[2].x, points[2].y);
            break;
        case 4:
            commands.push('C', points[1].x, points[1].y, points[2].x, points[2].y, points[3].x, points[3].y);
            break;
    }

    return commands.join(' ');
}

export default class MapFlows extends Component {
    constructor () {
        super();
    }

    render (props) {
        let points = this.calculateCurvePoints(props.a, props.b);

        let curve = new Bezier(points[0], points[1], points[2], points[3]);
        let length = curve.length();

        // FIXME: What is '12'?
        let arrowCurve = curve.split((length - 12 - (props.thickness * 0.25)) / length).left;
        let arrowPath = bezierToString(arrowCurve.points, true);

        let flowCurve = curve.split((length - 12 - (props.thickness * 0.5)) / length).left;
        let flowOutline = flowCurve.outline(0, 0, props.thickness, props.thickness);
        let flowTangent = Vector.fromObject(flowCurve.derivative(1)).normalize();
        let flowCapIndex = Math.ceil(flowOutline.curves.length / 2);

        let flowPath = flowOutline.curves.map(function (segment, i) {
            if (i === flowCapIndex) {
                let a = segment.points[0];
                let b = segment.points[3];

                let av = new Vector(a.x, a.y);
                let bv = new Vector(b.x, b.y);

                let offset = flowTangent.multiply(
                    new Vector(props.thickness * Math.PI / 2, props.thickness * Math.PI / 2)
                );

                av = av.add(offset);
                bv = bv.add(offset);

                segment.points[1] = av.toObject();
                segment.points[2] = bv.toObject();
            }

            return bezierToString(segment.points, i === 0);
        }).join(' ');

        return (
            <g>
                <path
                    id={ `arrow-${props.id}` }
                    d={ arrowPath }
                    marker-end="url(#arrowhead-map)"
                />
                <path id={ `flow-${props.id}` } d={ flowPath } />
            </g>
        )
    }

    calculateCurvePoints (a, b) {
        let av = new Vector(a.x, a.y);
        let bv = new Vector(b.x, b.y);

        let length = bv.distance(av);

        let arc = [
            {
                x: a.x,
                y: a.y,
                moveTo: true
            },
            {
                x: b.x,
                y: b.y,
                curve: {
                    type: 'arc',
                    rx: length,
                    ry: length,
                    xAxisRotation: 0,
                    largeArcFlag: 0,
                    sweepFlag: 1,
                }
            }
        ];

        let beziers = cubify(arc);

        if (beziers.length < 2) {
            console.error('Degenerate arc', a, b);
            return;
        }

        if (beziers.length > 2) {
            console.error('Complex arc', a, b);
            return;
        }

        let controlA = {
            x: beziers[1].curve.x1,
            y: beziers[1].curve.y1
        };

        let controlB = {
            x: beziers[1].curve.x2,
            y: beziers[1].curve.y2
        };

        return [
            { x: a.x, y: a.y },
            controlA,
            controlB,
            { x: b.x, y: b.y }
        ];
    }
}
