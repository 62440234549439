import React, { Component } from 'react';
import clsx from 'clsx';
import { geoStream, geoClipAntimeridian, geoPath } from 'd3-geo';
import { geoNaturalEarth as geoProjection } from 'd3-geo-projection';
import { feature as topojsonFeature } from 'topojson';

import MapCountries from '../map-countries/map-countries';
import MapDefs from '../map-defs/map-defs';
import MapLabels from '../map-labels/map-labels';
import MapFlows from '../map-flows/map-flows';
import MapHitboxes from '../map-hitboxes/map-hitboxes';
import MapBackground from '../MapBackground/svg.js';
import { DimensionsContext } from '../dimensions/dimensions.jsx';
import ZoomRegionsDebugLayer from '../ZoomRegionsDebugLayer';

import { matchBreakpoint } from '../../utilities/breakpoints.js';
import {
  getBoundsGeoPolygon,
  getZoomTransformFromBounds,
  getCoordinatesBounds,
} from '../../utilities/geometry.js';

import {
  TEST_REGION_BOUNDS,
  TEST_REGION_NAMES,
  ZOOM_ANTIMERIDIAN_COUNTRIES,
} from '../../constants.js';

import './_map.scss';

const world = require('../../assets/world-110m.json');

export default class Map extends Component {
  static contextType = DimensionsContext;

  constructor(props, context) {
    super();

    this.countries = topojsonFeature(world, world.objects.countries);

    // Skip Antarctica
    // FIXME: Remove from data so we don't load it?
    // This breaks the projection bounds
    this.countries.features = this.countries.features.filter(function (feature) {
      return feature.id !== 'AQ' || feature.id.length > 2;
    });

    this.projection = this.getProjection(context.width, context.height);

    // const transform = this.getRegionZoomTransform(props.zoomRegionId, context);
    const transform = {
      transform: null,
      transformRaw: null,
    };

    this.state = {
      ...transform,
      debug: false,
    };
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    if (this.context.width !== nextContext.width || this.context.height !== nextContext.height) {
      this.projection = this.getProjection(nextContext.width, nextContext.height);
    }
  }

  getProjection(width, height) {
    return geoProjection().fitSize([width, height], this.countries).rotate([-11.5, 0, 0]);
  }

  // TODO: Need to defer rendering defs, flows and hitboxes until labels
  // have been laid out?
  render(props, state, context) {
    const enableInteraction = matchBreakpoint('desktop').matches;
    let width = context.trueWidth;
    let height = context.trueHeight;
    let viewboxWidth = context.width;
    let viewboxHeight = context.height;

    if (props.width && props.height) {
      width = viewboxWidth = props.width;
      height = viewboxHeight = props.height;

      this.projection = this.getProjection(width, height);
    }

    console.log('>> zoom countries', props.zoomCountries);

    let transformObj = { transform: null, transformRaw: null };
    let zoomBounds = null;
    if (props.autoZoom && (props.zoomCountries || props.countries)) {
      transformObj = this.getCountryPointBoundsZoomTransform(
        props.zoomCountries || props.countries
      );
      // zoomBounds = this.getCountryPointBounds(props.zoomCountries || props.countries);
      // console.log('zoomBounds', zoomBounds);
    }

    return (
      <div
        className={clsx('map', props.kind && `map--${props.kind}`)}
        style={{ width: `${width}px`, height: `${height}px` }}
      >
        <svg className="map__svg" viewBox={`0 0 ${viewboxWidth} ${viewboxHeight}`}>
          <MapDefs
            data={props.data}
            countries={props.countries}
            projection={this.projection}
            scale={props.flowScale}
            width={viewboxWidth}
            height={viewboxHeight}
            transform={transformObj.transformRaw}
            wrapZoomCountries={props.autoZoom}
          />
          <g transform={transformObj.transform}>
            <g>
              <MapBackground x={0} y={0} width={viewboxWidth} height={viewboxHeight} />
            </g>
            <MapCountries countries={this.countries.features} projection={this.projection} />
            {state.debug && (
              <ZoomRegionsDebugLayer
                regionBounds={TEST_REGION_BOUNDS}
                projection={this.projection}
              />
            )}
            {/* {zoomBounds && (
              <rect
                x={zoomBounds[0][0]}
                y={zoomBounds[0][1]}
                width={zoomBounds[1][0] - zoomBounds[0][0]}
                height={zoomBounds[1][1] - zoomBounds[0][1]}
                fill="none"
                stroke="black"
              />
            )} */}
          </g>
          <g>
            <MapFlows
              data={props.data}
              hoveredFlow={props.hoveredFlow}
              hoveredCountry={props.hoveredCountry}
              activeCountry={props.activeCountry}
            />
            {enableInteraction && <MapHitboxes data={props.data} onHover={props.handleFlowHover} />}
            <MapLabels
              data={props.data}
              countries={props.countries}
              hoveredFlow={props.hoveredFlow}
              hoveredCountry={props.hoveredCountry}
              activeCountry={props.activeCountry}
              onHover={enableInteraction ? props.handleCountryHover : null}
              onClick={enableInteraction ? props.handleCountryClick : null}
            />
          </g>
        </svg>
        {/* <div
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            backgroundColor: 'white',
            padding: 4,
            textAlign: 'right',
          }}
        >
          <div>
            <select onChange={this.handleChangeRegion} value={this.props.zoomRegionId}>
              <option value="" selected>
                World
              </option>
              {Object.keys(TEST_REGION_BOUNDS).map((key) => {
                return <option value={key}>{TEST_REGION_NAMES[key]}</option>;
              })}
            </select>
          </div>
          <div>
            <button type="button" style="all:revert" onClick={this.handleClickQueryIntraregional}>
              Query intraregional
            </button>
          </div>
          <div>
            <label>
              <input
                type="checkbox"
                checked={state.debug}
                value="1"
                onChange={this.handleChangeDebug}
              />
              Debug
            </label>
          </div>
        </div> */}
      </div>
    );
  }

  handleChangeRegion = (event) => {
    console.log(event.currentTarget.value);
    if (this.props.onZoomToRegion) {
      this.props.onZoomToRegion(event.currentTarget.value);
    }
    this.setTransformToRegion(event.currentTarget.value);
  };

  handleChangeDebug = (event) => {
    this.setState({
      debug: event.target.checked,
    });
  };

  handleClickQueryIntraregional = (event) => {
    if (this.props.onQueryIntraregional) {
      this.props.onQueryIntraregional(this.props.zoomRegionId);
    }
  };

  setTransformToRegion(regionId) {
    const transform = this.getRegionZoomTransform(regionId);
    this.setState(transform);
  }

  setTransformToCountryPointBounds(countries) {
    const transform = this.getCountryPointBoundsZoomTransform(countries);
    this.setState(transform);
  }

  getRegionZoomTransform(regionId, context) {
    if (!regionId) {
      return { transform: null, transformRaw: null };
    }

    context = context || this.context;

    const bounds = TEST_REGION_BOUNDS[regionId];
    const polygon = getBoundsGeoPolygon(bounds);
    const path = geoPath().projection(this.projection);
    const pathBounds = path.bounds(polygon);

    return getZoomTransformFromBounds(pathBounds, context);
  }

  // FIXME: Bounds are calculated using unlaid-out country coordinates
  getCountryPointBounds(countries, context) {
    console.log('zoom countries', countries);

    if (!countries || !countries.length) {
      return null;
    }

    context = context || this.context;

    const coordinates = countries.map((country) => {
      return [country.model.lng, country.model.lat];
    });
    const geojson = { type: 'MultiPoint', coordinates: coordinates };

    const path = geoPath().projection(this.projection);
    const pathBounds = path.bounds(geojson);

    // if (!!countries.find((d) => d.id === 258)) {
    //   console.log('pathBounds', pathBounds);
    //   pathBounds[0][0] = 1000;
    //   pathBounds[1][0] = 2000;
    // }

    console.log('pathBounds', pathBounds);

    const coords = countries.map((country) => {
      const coord = this.projection([country.model.lng, country.model.lat]);
      if (ZOOM_ANTIMERIDIAN_COUNTRIES.includes(country.id)) {
        coord[0] = coord[0] + context.width;
      }

      return coord;
    });

    const bounds = getCoordinatesBounds(coords);
    console.log('bounds', bounds);

    return bounds;

    // return pathBounds;
  }

  getCountryPointBoundsZoomTransform(countries, context) {
    /*
    if (!countries || !countries.length) {
      return { transform: null, transformRaw: null };
    }

    context = context || this.context;

    const coordinates = countries.map((country) => {
      return [country.model.lng, country.model.lat];
    });
    const geojson = { type: 'MultiPoint', coordinates: coordinates };

    const path = geoPath().projection(this.projection);
    const pathBounds = path.bounds(geojson);
    */

    context = context || this.context;

    const bounds = this.getCountryPointBounds(countries, context);

    if (!bounds) {
      return { transform: null, transformRaw: null };
    }

    return getZoomTransformFromBounds(bounds, context);
  }
}
