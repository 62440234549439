import React, { Component } from 'react';
import get from 'lodash/get';

import TabsContainer from '../../components/tabs/tabs';
import Tablist from '../../components/tablist/tablist';
import Tab from '../../components/tab/tab';
import Tabpanel from '../../components/tabpanel/tabpanel';
import Modal from '../../components/modal/modal';
import Units from '../../components/units/units';
import CountryInfo from '../../components/country-info/country-info';
import DataWarning from '../../components/data-warning/data-warning';
import Loader from '../../components/loader/loader';
import Portal from '../../components/portal/portal';
import DashboardFootprints from '../../components/dashboard-footprints/dashboard-footprints';
import MapMenu from '../../components/map-menu/map-menu';
import Popover from '../../components/popover/popover';
import NesInfo from '../../components/nes-info/nes-info';
import ShareButton from '../../components/share-button/share-button.jsx';
import Download from '../../components/download/download.jsx';
import Dashboard from '../../components/Dashboard';
import {
  MapWrapper,
  MapWrapperMain,
  MapWrapperFooter,
  MapWrapperHeader,
} from '../../components/MapWrapper';
import MapContainer from '../../components/map-container/map-container.jsx';
import Tooltip from '../../components/Tooltip';
import TradeReadout from '../../components/TradeReadout';
import MapActions from '../../components/MapActions';
import WelcomeModalButton from '../../components/WelcomeModalButton';
import TradeDonutChart from '../../components/TradeDonutChart';

import DashboardTradesContainer from '../../containers/dashboard-trades/dashboard-trades';
import DashboardCommoditiesContainer from '../../containers/dashboard-commodities/dashboard-commodities';
import DashboardCountriesContainer from '../../containers/dashboard-countries/dashboard-countries';
import DashboardChartContainer from '../../containers/dashboard-chart/dashboard-chart';
import QueryDesktopContainer from '../../containers/query-desktop/query-desktop';
// import DashboardDonutChartContainer from '../../containers/dashboard-donut/dashboard-donut';

import './styles.scss';

// let MapContainer;

// TODO: Refactor to container
export default class TradeView extends Component {
  constructor(props) {
    super();

    this.store = props.store;
    this.store.listen(this.handleStoreChange.bind(this));

    this.state = this.store.getState();

    // Store data does not change
    this.data = this.store.getData();
  }

  handleStoreChange() {
    this.setState(this.store.getState());
  }

  render(props, state) {
    let _this = this;

    return (
      <div className="trade-view">
        <QueryDesktopContainer
          query={state.query}
          data={this.data}
          autoZoom={state.query.autozoom}
          handleSelect={this.handleQuerySelect}
          handleTranspose={this.handleQueryTranspose}
          handlePopoverToggle={this.handleQueryPopoverToggle}
          handleChangeAutoZoom={this.handleChangeAutoZoom}
        />
        {/*
        <div className="inset u-contain">
          <div className="map-header">
            <div className="map-header__main">
              <div className="map-header__left">
                <Units current={state.query.units} handleChange={this.handleUnitsChange} />
                <DashboardDonutChartContainer
                  title={
                    state.query.category.depth > 1
                      ? `Share of global ${state.query.category.parent.model.name.toLowerCase()} trade`
                      : 'Share of all commodities trade'
                  }
                  data={get(state.results.historical.data, 'percentages')}
                  query={state.query}
                />
              </div>
              <div className="map-header__right">
                <ShareButton
                  kind="bold"
                  message="Explore interactive resource trade data from Chatham House"
                  simple={false}
                  query={state.query}
                  popoverAlign="right"
                />
              </div>
            </div>
          </div>
          <div className="u-contain">
            <Loader visible={state.results.trades.status !== 'ready'} transparent={true} />
            <DataWarning query={state.query} data={get(state.results.trades.data, 'main')} />
            {this.renderMap(props, state)}
          </div>
        </div>
        */}
        {/* <MapWrapper>
          <MapWrapperHeader modifier="trade">
            <Units current={state.query.units} handleChange={this.handleUnitsChange} />
            <TradeDonutChart
              title={
                state.query.exporter.id || state.query.importer.id
                  ? `Share of global ${state.query.category.model.name.toLowerCase()} trade`
                  : state.query.category.depth > 1
                  ? `Share of global ${state.query.category.parent.model.name.toLowerCase()} trade`
                  : 'Share of all commodities trade'
              }
              data={get(state.results.historical.data, 'percentages')}
              query={state.query}
            />
          </MapWrapperHeader>
          <MapWrapperMain>
            <Loader visible={state.results.trades.status !== 'ready'} transparent={true} />
            {this.renderMap(props, state)}
          </MapWrapperMain>
        </MapWrapper> */}
        {this.renderMap(props, state)}
        <div className="trade-view__dashboard">
          <Dashboard
            chart={
              <>
                <Loader visible={state.results.historical.status !== 'ready'} />
                <DashboardChartContainer
                  title={`Total ${state.query.units}`}
                  data={get(state.results.historical.data, 'totals')}
                  query={state.query}
                  years={this.data.years}
                />
              </>
            }
            tabs={
              <TabsContainer>
                <Tablist>
                  <Tab>Trade flows</Tab>
                  <Tab>Commodities</Tab>
                  <Tab>Exporters</Tab>
                  <Tab>Importers</Tab>
                  {__config.hasFootprints && <Tab>Footprints</Tab>}
                </Tablist>
                <Tabpanel>
                  <Loader visible={state.results.trades.status !== 'ready'} />
                  <DashboardTradesContainer
                    data={state.results.trades.data}
                    units={state.query.units}
                    prevQuery={state.prevQuery}
                    handleClick={this.handleListClick}
                    handleHover={this.handleFlowHover}
                    handleBack={this.handleListBackClick}
                  />
                </Tabpanel>
                <Tabpanel>
                  <Loader visible={state.results.commodities.status !== 'ready'} />
                  <DashboardCommoditiesContainer
                    data={state.results.commodities.data}
                    active={state.query.category}
                    units={state.query.units}
                    handleClick={this.handleListClick}
                  />
                </Tabpanel>
                <Tabpanel>
                  <Loader visible={state.results.exporters.status !== 'ready'} />
                  <DashboardCountriesContainer
                    data={state.results.exporters.data}
                    units={state.query.units}
                    active={state.query.exporter}
                    prevQuery={state.prevQuery}
                    type="exporter"
                    handleClick={this.handleListClick}
                    handleBack={this.handleListBackClick}
                  />
                </Tabpanel>
                <Tabpanel>
                  <Loader visible={state.results.importers.status !== 'ready'} />
                  <DashboardCountriesContainer
                    data={state.results.importers.data}
                    units={state.query.units}
                    active={state.query.importer}
                    prevQuery={state.prevQuery}
                    type="importer"
                    handleClick={this.handleListClick}
                    handleBack={this.handleListBackClick}
                  />
                </Tabpanel>
                {__config.hasFootprints && (
                  <Tabpanel>
                    <Loader visible={state.results.footprints.status !== 'ready'} />
                    <DashboardFootprints data={state.results.footprints.data} />
                  </Tabpanel>
                )}
              </TabsContainer>
            }
          />
        </div>
        {state.showTooltip && state.hoveredFlow && (
          <Tooltip>
            <TradeReadout
              data={state.hoveredFlow}
              totals={state.results.totals}
              query={state.query}
            />
          </Tooltip>
        )}
        <Portal open={state.activeCountry && state.mapMenuOpen && state.mapMenuRect}>
          <Popover rect={state.mapMenuRect} onClose={this.handleMapMenuClose}>
            <MapMenu
              country={state.activeCountry}
              rect={state.mapMenuRect}
              query={state.query}
              onProfileClick={this.handleProfileClick}
              onNESProfileClick={this.handleNESProfileClick}
              onCountryClick={this.handleCountryClick}
              onCountryTransposeClick={this.handleCountryTransposeClick}
            />
          </Popover>
        </Portal>
        <Portal open={state.countryModalOpen} modal={true}>
          <Modal onClose={this.handleCountryModalClose}>
            <CountryInfo country={state.activeCountry} />
          </Modal>
        </Portal>
        <Portal open={state.nesModalOpen} modal={true}>
          <Modal onClose={this.handleNESModalClose}>
            <NesInfo />
          </Modal>
        </Portal>
      </div>
    );
  }

  renderMap(props, state) {
    // if (typeof MapContainer === 'undefined') {
    //   return <MapContainerStub />;
    // }

    return (
      <MapContainer
        query={state.query}
        data={get(state.results.trades.data, 'main')}
        total={get(state.results.trades.data, 'total')}
        hoveredFlow={state.hoveredFlow}
        hoveredCountry={state.hoveredCountry}
        activeCountry={state.activeCountry}
        handleFlowHover={this.handleFlowHover}
        handleCountryHover={this.handleCountryHover}
        handleCountryClick={this.handleMapLabelClick}
        header={
          <>
            <Units
              current={state.query.units}
              disableWeight={__config.disableTradeWeightUnits}
              handleChange={this.handleUnitsChange}
            />
            {/* FIXME: Send something in API response to indicate which text should be shown? */}
            <TradeDonutChart
              title={
                state.query.exporter.id || state.query.importer.id
                  ? `Share of global ${state.query.category.model.name.toLowerCase()} trade`
                  : state.query.category.depth > 1
                  ? `Share of global ${state.query.category.parent.model.name.toLowerCase()} trade`
                  : 'Share of all commodities trade'
              }
              data={get(state.results.historical.data, 'percentages')}
              query={state.query}
            />
          </>
        }
        loading={state.results.trades.status !== 'ready'}
        zoomRegionId={state.zoomRegion}
        autoZoom={state.query.autozoom}
        onZoomToRegion={this.handleZoomToRegion}
        onQueryIntraregional={this.handleQueryIntraregional}
      />
    );
  }

  handleMapMenuClose = () => {
    this.store.dispatch('HIDE_COUNTRY_MENU');
  };

  handleCountryModalClose = () => {
    this.store.dispatch('HIDE_COUNTRY_MODAL');
  };

  handleProfileClick = (country) => {
    this.store.dispatch('SHOW_COUNTRY_MODAL');
  };

  handleNESProfileClick = () => {
    this.store.dispatch('SHOW_NES_MODAL');
  };

  handleNESModalClose = () => {
    this.store.dispatch('HIDE_NES_MODAL');
  };

  handleCountryClick = (type, country) => {
    let query = this.state.query.clone();

    if (query[type] !== country) {
      query[type] = country;
    } else {
      query[type] = this.data.countries.getDefault();
    }

    this.store.dispatch('HIDE_COUNTRY_MENU');
    this.store.dispatch('SET_QUERY', query);
  };

  handleCountryTransposeClick = (type, country) => {
    let oppositeType = type === 'exporter' ? 'importer' : 'exporter';

    let query = this.state.query.clone();

    query[oppositeType] = query[type];
    query[type] = country;

    this.store.dispatch('HIDE_COUNTRY_MENU');
    this.store.dispatch('SET_QUERY', query);
  };

  handleModalButtonClick = () => {
    this.store.dispatch('TOGGLE_MODAL', true);
  };

  handleModalClose() {
    this.store.dispatch('TOGGLE_MODAL', false);
  }

  handleModalSubmit(query) {
    // FIXME: Does this cause data to be refetched when units change?
    this.store.dispatch('SET_QUERY', query);
    this.store.dispatch('TOGGLE_MODAL', false);
  }

  handleShareModalButtonClick = () => {
    this.store.dispatch('TOGGLE_SHARE_MODAL', true);
  };

  handleShareModalClose() {
    this.store.dispatch('TOGGLE_SHARE_MODAL', false);
  }

  handleQuerySelect = (id, data) => {
    let query = this.state.query.clone();

    query.set(id, data);

    this.store.dispatch('SET_QUERY', query);
  };

  handleQueryTranspose = () => {
    let query = this.state.query.clone();

    query.exporter = this.state.query.importer;
    query.importer = this.state.query.exporter;

    this.store.dispatch('SET_QUERY', query);
  };

  handleQueryPopoverToggle = (open) => {
    console.log('handleQueryPopoverToggle', open);

    this.store.dispatch('TOGGLE_QUERY_POPOVER', open);
  };

  handleListClick = (data) => {
    let action = 'SET_QUERY';

    let query = this.state.query.clone();

    // FIXME: Use Object.assign?
    // FIXME: Add a seperate action and move this into store?
    // if (data.year) {
    //     query.year = data.year;
    // }

    if (data.exporter) {
      query.exporter = data.exporter;
      action = 'SET_QUERY_LOGGED';
    }

    if (data.importer) {
      query.importer = data.importer;
      action = 'SET_QUERY_LOGGED';
    }

    if (data.category) {
      query.category = data.category;
    }

    this.store.dispatch(action, query);
    this.store.dispatch('SET_SHOW_TOOLTIP', false);
  };

  handleListBackClick = (query) => {
    this.store.dispatch('SET_QUERY', query);
  };

  handleUnitsChange = (value) => {
    this.store.dispatch('SET_UNITS', value);
  };

  // handleBreakpointChange = () => {
  //   this.maybeLoadMap();
  // };

  handleFlowHover = (data, point) => {
    this.store.dispatch('SET_HOVERED_FLOW', { data: data, point: point });
    this.store.dispatch('SET_SHOW_TOOLTIP', !!data && !!point);
  };

  handleCountryHover = (country) => {
    this.store.dispatch('SET_HOVERED_COUNTRY', country);
  };

  handleDownloadStart = (request) => {
    this.store.dispatch('SET_DOWNLOAD_PENDING', request);
  };

  handleDownloadComplete = () => {
    this.store.dispatch('SET_DOWNLOAD_PENDING', null);
  };

  handleMapLabelClick = (country, rect) => {
    this.store.dispatch('SET_ACTIVE_COUNTRY', {
      country: country,
      rect: rect,
    });
  };

  handleZoomToRegion = (regionId) => {
    this.store.dispatch('SET_ZOOM_REGION', regionId);
  }

  handleQueryIntraregional = (regionId) => {
    const region = this.data.countryGroups.get(regionId);
    console.log('query intraregional', regionId, region);

    if (!region) {
      return;
    }

    const query = this.state.query.clone();

    query.exporter = region;
    query.importer = region;

    this.store.dispatch('SET_QUERY', query);
  }

  handleChangeAutoZoom = (checked) => {
    const query = this.state.query.clone();
    query.autozoom = checked;
    this.store.dispatch('SET_QUERY', query);
  }
}
