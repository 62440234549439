import React, { Component } from 'react';

import QueryDesktop from '../../components/query-desktop/query-desktop';

// FIXME: Can be generic across breakpoints?
export default class QueryDesktopContainer extends Component {
    shouldComponentUpdate (nextProps) {
        return (this.props.query !== nextProps.query);
    }

    render (props) {
        let disabledCountries = [];
        let disabledYears = [];

        props.data.countries.forEach(function (country) {
            if (country.model.start_year > props.query.year.id ||
                country.model.end_year < props.query.year.id)
            {
                disabledCountries.push(country);
            }
        });

        if (props.query.exporter && props.query.exporter.model.start_year) {
            props.data.years.forEach(function (year) {
                if (year.id < props.query.exporter.model.start_year ||
                    year.id > props.query.exporter.model.end_year)
                {
                    disabledYears.push(year);
                }
            });
        }

        if (props.query.importer && props.query.importer.model.start_year) {
            props.data.years.forEach(function (year) {
                if (year.id < props.query.importer.model.start_year ||
                    year.id > props.query.importer.model.end_year)
                {
                    disabledYears.push(year);
                }
            });
        }

        return (
            <QueryDesktop
                { ...props }
                disabledCountries={ disabledCountries}
                disabledYears={ disabledYears}
            />
        );
    }

    handleSelect (id, node) {
        this.props.handleSelect(id, node);
    }

    handleTranspose () {
        this.props.handleTranspose();
    }
}
