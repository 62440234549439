import React, { Component } from 'react';
import sortBy from 'lodash/sortBy';
import union from 'lodash/union';
import { scaleLinear } from 'd3-scale';

import Dimensions from '../dimensions/dimensions';
import Map from '../map/zoom-test3.js';
import MapCounter from '../map-counter/map-counter';
import MapLegend from '../map-legend/map-legend';
import MiniMapLegend from '../MiniMapLegend';
import MapActions from '../../components/MapActions';
import WelcomeModalButton from '../../components/WelcomeModalButton';
import Download from '../../components/download/download.jsx';
import ShareButton from '../../components/share-button/share-button.jsx';
import {
  MapWrapper,
  MapWrapperMain,
  MapWrapperFooter,
  MapWrapperHeader,
} from '../../components/MapWrapper';
import Loader from '../../components/loader/loader.jsx';

import formatters from '../../formatters';
import { nice } from '../../helpers';
import { filterZoomGroupsExcludedCountries } from './utils.js';

// import './map-container.scss';
// import './styles.scss';

export default class MapContainer extends Component {
  constructor(props) {
    super();

    this.state = {
      data: null,
      flowScale: null,
      countries: null,
    };

    this.prepareData(props.data, props.query);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.data !== nextProps.data || this.props.query !== nextProps.query) {
      this.prepareData(nextProps.data, nextProps.query);
    }
  }

  // XXX: Setting state from props is an antipattern in React, but if we process data in render
  // we have to do it even if only hovered flow/country has changed (in which case we don't want
  // to rerender defs) but we then end up with a different object and not compare to previous
  // props in shouldComponentUpdate
  prepareData(rawData, query) {
    if (!rawData || !rawData.length) {
      this.setState({
        data: null,
        flowScale: null,
        countries: null,
      });

      return;
    }

    let data = [];

    rawData.forEach(function (datum) {
      const value = datum[query.units];

      // Skip flows with no data, which will be where there is value but no weight data
      // e.g Albania → Belgium, Cement, 2015
      // FIXME: Would be better to prefilter the returned data and fall through to existing
      // no data handling
      if (value === null) {
        return;
      }

      data.push({
        exporter: datum.exporter,
        importer: datum.importer,
        value: value,
        original: datum,
      });
    });

    data = sortBy(data, ['value']).reverse();

    let max = data.length ? data[0].value : null;
    max = nice(max);

    const flowScale = scaleLinear().domain([0, max]).range([0, 12]);

    // Filter out small flows based on thickness scale
    data = data.filter(function (datum, i) {
      return i < 5 || flowScale(datum.value) >= 0.9;
    });

    const countries = data.reduce(function (memo, datum) {
      if (memo.indexOf(datum.exporter) < 0) {
        memo.push(datum.exporter);
      }

      if (memo.indexOf(datum.importer) < 0) {
        memo.push(datum.importer);
      }

      return memo;
    }, []);

    console.log('>', query.exporter, query.importer);
    // let zoomCountries = [];
    // If either exporter or importer is 'all countries' don't set zoom countries
    // if (query.exporter.id && query.importer.id) {
    //   if (query.exporter.children) {
    //     zoomCountries = zoomCountries.concat(
    //       filterZoomGroupsExcludedCountries(query.exporter.id, query.exporter.children)
    //     );
    //   } else if (query.exporter.model) {
    //     zoomCountries = zoomCountries.concat([query.exporter]);
    //   }
    //   if (query.importer.children) {
    //     zoomCountries = zoomCountries.concat(
    //       filterZoomGroupsExcludedCountries(query.importer.id, query.importer.children)
    //     );
    //   } else if (query.importer.model) {
    //     zoomCountries = zoomCountries.concat([query.importer]);
    //   }
    // }
    let zoomCountries = query.exporter.id || query.importer.id ? [...countries] : [];
    // FIXME: Zoom group excluded countries are not excluded if they are already in the above countries array
    if (query.exporter.children && query.exporter.children.length) {
      zoomCountries = union(zoomCountries, filterZoomGroupsExcludedCountries(query.exporter.id, query.exporter.children));
    }
    if (query.importer.children && query.importer.children.length) {
      zoomCountries = union(zoomCountries, filterZoomGroupsExcludedCountries(query.importer.id, query.importer.children));
    }
    console.log('countries', countries, zoomCountries);

    this.setState({
      data: data,
      flowScale: flowScale,
      countries: countries,
      zoomCountries: zoomCountries,
    });
  }

  render(props, state) {
    let children = null;

    // if (props.fixed) {
    //   if (state.data && state.data.length) {
    //     return (
    //       <Map
    //         {...props}
    //         width={1536}
    //         height={744}
    //         data={state.data}
    //         flowScale={state.flowScale}
    //         countries={state.countries}
    //       />
    //     );
    //   } else {
    //     return null;
    //   }
    // }

    if (state.data && state.data.length) {
      children = (
        // Below tablet breakpoint force the map to scale down rather than relayout
        // FIXME: Get dimensions from breakpoints or CSS?
        <Dimensions minWidth={748} minHeight={343}>
          <Map
            {...props}
            data={state.data}
            flowScale={state.flowScale}
            countries={state.countries}
            zoomCountries={state.zoomCountries}
          />
        </Dimensions>
      );
    }

    const formatter = props.query.units === 'value' ? formatters.currency : formatters.weight;

    // return children;

    return (
      <MapWrapper modifier={props.kind}>
        {props.header && <MapWrapperHeader modifier="trade">{props.header}</MapWrapperHeader>}
        <MapWrapperMain>
          <Loader visible={props.loading} transparent={true} />
          {children}
        </MapWrapperMain>
        <MapWrapperFooter modifier="trade">
          {state.flowScale ? <MapLegend scale={state.flowScale} formatter={formatter} /> : null}
          {/* Don't show mini map legend on embeds */}
          {!props.kind && <MiniMapLegend />}
          {!props.kind && (
            <MapActions
              status={
                state.data && state.data.length ? (
                  <MapCounter count={state.data.length} total={props.total} />
                ) : null
              }
            >
              <WelcomeModalButton />
              <ShareButton queryString={props.query.toString()} message={__config.twitterMessage} />
              <Download
                query={props.query}
                // handleStart={this.handleDownloadStart}
                // handleComplete={this.handleDownloadComplete}
              />
            </MapActions>
          )}
        </MapWrapperFooter>
      </MapWrapper>
    );

    // return (
    //     <div className={ bemName('map-container', props.kind) }>
    //         <div className="map-container__map">
    //             <div className="map-container__map__main">
    //                 { children }
    //             </div>
    //         </div>
    //         <div className="map-container__footer">
    //             <div className="map-container__footer__main">
    //                 <div className="map-container__footer__left">
    //                     {
    //                         state.flowScale ?
    //                         <MapLegend
    //                             scale={ state.flowScale }
    //                             formatter={ formatter }
    //                         /> :
    //                         null
    //                     }
    //                     { props.footerLeftChildren }
    //                 </div>
    //                 <div className="map-container__footer__right">
    //                     {
    //                         state.data && state.data.length ?
    //                         <MapCounter
    //                             count={ state.data.length }
    //                             total={ props.total }
    //                         /> :
    //                         null
    //                     }
    //                     {
    //                         state.data && state.data.length ?
    //                         props.footerRightChildren :
    //                         null
    //                     }
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // );
  }
}
