import React, { Component } from 'react';
import each from 'lodash/each';

import TabsContainer from '../../components/tabs/tabs';
import Tablist from '../tablist/tablist';
import Tab from '../tab/tab';
import Tabpanel from '../tabpanel/tabpanel';
import FootprintsChart from '../footprints-chart/footprints-chart';
import Loader from '../loader/loader';
import CountryInfoTable from '../CountryInfoTable';
import InfoModal from '../InfoModal';

import formatters from '../../formatters';

import './_country-info.scss';

const STRINGS = require('./labels.json');

const ROWS = {
  env: [
    { id: 'env_ghg_emissions', formatter: formatters.null },
    { id: 'env_air_quality', formatter: formatters.score },
    { id: 'env_water_stress', formatter: formatters.percentage },
    { id: 'env_tree_cover_loss', formatter: formatters.percentage },
    { id: 'env_biodiversity', formatter: formatters.score },
  ],
  soc: [
    { id: 'soc_hdi', formatter: formatters.score },
    { id: 'soc_inequality', formatter: formatters.null },
    { id: 'soc_poverty_ratio', formatter: formatters.percentage },
    { id: 'soc_urban_population', formatter: formatters.percentage },
    { id: 'soc_electricity_access', formatter: formatters.percentage },
  ],
  gov: [
    { id: 'gov_corruption', formatter: formatters.score },
    { id: 'gov_voice_accountability', formatter: formatters.null },
    { id: 'gov_stability', formatter: formatters.null },
    { id: 'gov_effectiveness', formatter: formatters.null },
    { id: 'gov_infrastructure', formatter: formatters.score },
  ],
  res: [
    { id: 'res_exports', formatter: formatters.percentage },
    { id: 'res_imports', formatter: formatters.percentage },
    { id: 'res_balance', formatter: formatters.signedCurrency },
    { id: 'res_rents', formatter: formatters.percentage },
  ],
};

export default class CountryInfo extends Component {
  constructor() {
    super();

    this.state = {
      status: 'pending',
      data: null,
    };
  }

  // TODO: Move fetching into container component?
  componentDidMount() {
    this.fetchData(this.props.country.id);
  }

  componentWillUnmount() {
    if (this.request) {
      this.request.abort();
    }
  }

  render(props, state) {
    let tableRows;

    if (state.data) {
      tableRows = {};

      each(ROWS, function (items, key) {
        tableRows[key] = items.map(function (item) {
          const rankID = item.id.replace('_', '_rank_');

          return {
            label: STRINGS[item.id].label,
            tooltip: STRINGS[item.id].tooltip,
            rank: state.data[rankID],
            value: item.formatter(state.data[item.id]),
          };
        });
      });
    }

    return (
      <div className="countryinfo u-contain">
        <InfoModal header={<h1>{props.country.model.name}</h1>}>
          <Loader visible={state.status !== 'ready'} transparent={true} />
          {this.renderMain(tableRows, state.data)}
        </InfoModal>
      </div>
    );
  }

  renderMain(tableRows, data) {
    // Can't render tabs with no data as registration will break conditional profile tab
    if (!data) {
      return null;
    }

    return (
      <TabsContainer>
        <Tablist kind="outlined">
          {data.profile ? <Tab kind="outlined">Profile</Tab> : null}
          <Tab kind="outlined">Environmental</Tab>
          <Tab kind="outlined">Socio-economic</Tab>
          <Tab kind="outlined">Governance</Tab>
          <Tab kind="outlined">Resource dependence</Tab>
        </Tablist>
        {data.profile ? this.renderProfile(data.profile, data.chart) : null}
        <Tabpanel>
          <div className="countryinfo__tabpanel">
            <CountryInfoTable rows={tableRows && tableRows.env} />
          </div>
          {this.renderFootnotes()}
        </Tabpanel>
        <Tabpanel>
          <div className="countryinfo__tabpanel">
            <CountryInfoTable rows={tableRows && tableRows.soc} />
          </div>
          {this.renderFootnotes()}
        </Tabpanel>
        <Tabpanel>
          <div className="countryinfo__tabpanel">
            <CountryInfoTable rows={tableRows && tableRows.gov} />
          </div>
          {this.renderFootnotes()}
        </Tabpanel>
        <Tabpanel>
          <div className="countryinfo__tabpanel">
            <CountryInfoTable rows={tableRows && tableRows.res} />
          </div>
          {this.renderFootnotes()}
        </Tabpanel>
      </TabsContainer>
    );
  }

  renderProfile(data, chart) {
    return (
      <Tabpanel>
        <div className="countryinfo__tabpanel">
          <div className="countryinfo__profile">
            <div className="countryinfo__profile__column">
              <p>{data}</p>
            </div>
            <div className="countryinfo__profile__column">
              <figure>
                <figcaption>Trade as % of GDP</figcaption>
                <FootprintsChart
                  data={chart}
                  order={['exports', 'imports']}
                  formatter={formatters.percentage}
                  // TODO: Update colours to match theme
                  colours={['#df376f', '#00aeef']}
                  modifier="countryinfo"
                />
              </figure>
            </div>
          </div>
        </div>
      </Tabpanel>
    );
  }

  renderFootnotes() {
    return (
      <div className="countryinfo__footnotes">
        <p className="countryinfo__footnote">
          *Percentage of countries with data that have an equal or worse score (100 is best, 0 is
          worst)
        </p>
        <p className="countryinfo__footnote">
          <a href="/about">Find out more about these indicators</a>
        </p>
      </div>
    );
  }

  fetchData(countryID) {
    if (!countryID) {
      return;
    }

    if (this.request) {
      this.request.abort();
    }

    let request = new XMLHttpRequest();

    request.open('GET', `${__config.api_path}countrymeta/${countryID}`, true);

    // TODO: Move to top level function?
    request.onload = function () {
      if (request.status >= 200 && request.status < 400) {
        this.request = null;

        let raw = JSON.parse(request.responseText);

        this.setState({
          status: 'ready',
          data: raw[0],
        });
      }
    }.bind(this);

    this.setState({
      status: 'pending',
      data: null,
    });

    request.send();

    this.request = request;
  }
}
