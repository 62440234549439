'use strict';

// var FlatCollection = require('../shared/collection-flat'),
//     ParentCollection = require('../shared/collection-parent'),
//     TreeCollection = require('../shared/collection-tree'),
//     Query = require('../shared/query'),
//     queryString = require('qs'),
//     raf = require('raf');

// let objectKeys = require('lodash/keys');

const queryString = require('qs');
const objectKeys = require('lodash/keys');

import FlatCollection from '../shared/collection-flat';
import ParentCollection from '../shared/collection-parent';
import TreeCollection from '../shared/collection-tree';
import Query from '../shared/query';

import { replaceLegacyEconomyIds } from './utilities/legacy.js';

var data = {},
  state = {};

// FIXME
let _data = data;

export default {
  init: function (raw, params) {
    this.listeners = [];

    this.pendingRequests = {};
    this.delayedRequestTimeouts = {};

    data.years = new FlatCollection(raw.years);

    data.categories = new TreeCollection(raw.categories);
    data.categories.setDefault({ id: null, name: 'All commodities' });

    data.countries = new FlatCollection(raw.countries, true);
    data.countries.setDefault({ id: null, name: 'All countries' });

    data.categoryGroups = new ParentCollection(raw.categoryGroups, data.categories);
    data.countryGroups = new ParentCollection(raw.countryGroups, data.countries);

    data.countryGroupsMain = data.countryGroups.filter('type', 'group');
    data.countryGroupsRegions = data.countryGroups.filter('type', 'region');

    this.resetState();

    // XXX: Add data to query class (so its not always required as a parameter)
    Query.data = data;

    const [normalisedParams, paramsDirty] = replaceLegacyEconomyIds(params);
    state.query = new Query(normalisedParams, data);

    // Update the URL if we replaced legacy economy IDs
    if (paramsDirty) {
      this.updateURL(state.query, true);
    }

    window.addEventListener(
      'popstate',
      function () {
        let params = queryString.parse(window.location.search.replace(/^\?/, ''));
        let query = new Query(params, data);
        this.dispatch('RESTORE_QUERY', query, true);
      }.bind(this)
    );

    let _this = this;

    if (document.readyState === 'complete') {
      _this.fetchData(state.query);
    } else {
      // TODO: Remove event listener after load?
      window.addEventListener('load', function () {
        _this.fetchData(state.query);
      });
    }
  },

  resetState: function () {
    state = {
      query: null,
      prevQuery: null,
      results: {
        trades: {
          status: 'pending',
          data: null,
        },
        commodities: {
          status: 'pending',
          data: null,
        },
        exporters: {
          status: 'pending',
          data: null,
        },
        importers: {
          status: 'pending',
          data: null,
        },
        footprints: {
          status: 'pending',
          data: null,
        },
        historical: {
          status: 'pending',
          data: null,
        },
        totals: {
          status: 'pending',
          data: null,
        },
      },
      modalOpen: false,
      shareModalOpen: false,
      activeCountry: null,
      countryModalOpen: false,
      mapLoaded: false,
      hoveredFlow: null,
      hoveredFlowPoint: null,
      hoveredCountry: null,
      mapMenuOpen: false,
      mapMenuRect: null,
      nesModalOpen: false,
      queryPopoverOpen: false,
      pendingDownload: null,
      showTooltip: false,
      zoomRegion: '',
      autoZoom: true,
    };
  },

  getState: function (key) {
    return key ? state[key] : state;
  },

  getData: function (key) {
    return key ? data[key] : data;
  },

  rehydrate: function (raw) {
    if (!Array.isArray(raw)) {
      objectKeys(raw).forEach(function (key) {
        raw[key] = this.rehydrate(raw[key]);
      }, this);
      return raw;
    } else {
      return raw.map(function (datum) {
        const originalDatum = {...datum};

        if (datum.year) {
          datum.year = data.years.get(datum.year);
        }

        if (datum.exporter) {
          if (isFinite(datum.exporter)) {
            datum.exporter = data.countries.get(datum.exporter);
          } else {
            datum.exporter = data.countryGroups.get(datum.exporter);
          }
        }

        if (originalDatum.exporter && !datum.exporter) {
          console.warn('Failed to find exporter for datum', originalDatum);
        }

        if (datum.importer) {
          if (isFinite(datum.importer)) {
            datum.importer = data.countries.get(datum.importer);
          } else {
            datum.importer = data.countryGroups.get(datum.importer);
          }
        }

        if (originalDatum.importer && !datum.importer) {
          console.warn('Failed to find importer for datum', originalDatum);
        }

        if (datum.category) {
          if (isFinite(datum.category)) {
            datum.category = data.categories.get(datum.category);
          } else {
            datum.category = data.categoryGroups.get(datum.category);
          }
        }

        return datum;
      });
    }
  },

  // XXX: Temporary methods for testing updating state

  listen: function (callback) {
    this.listeners.push(callback);
  },

  dispatch: function (action, data) {
    console.log(action, data);

    if (
      state.pendingDownload &&
      (action === 'SET_QUERY' ||
        action === 'SET_QUERY_LOGGED' ||
        action === 'RESTORE_QUERY' ||
        action === 'SET_YEAR')
    ) {
      if (
        !window.confirm(
          'Your data download is still pending. Click cancel to return to the page and allow the download to complete.'
        )
      ) {
        return;
      } else {
        state.pendingDownload.cancel();
      }
    }

    // TODO: Skip actions which don't change state
    switch (action) {
      case 'SET_QUERY':
        if (state.query.equals(data)) {
          if (state.query.units !== data.units) {
            this.dispatch('SET_UNITS', data.units);
          }

          if (state.query.autozoom !== data.autozoom) {
            // Force map to rerender without reloading data
            state.prevQuery = null;
            state.query = data;
            this.updateURL(state.query);
            this.notify();
          }

          return;
        }
        state.prevQuery = null;
        state.query = data;
        this.updateURL(state.query);
        this.fetchData(state.query);
        break;
      case 'SET_QUERY_LOGGED':
        if (state.query.equals(data)) {
          return;
        }
        state.prevQuery = state.query;
        state.query = data;
        this.updateURL(state.query);
        this.fetchData(state.query);
        break;
      case 'RESTORE_QUERY':
        // Update query without updating the URL
        state.prevQuery = null;
        state.query = data;
        this.fetchData(state.query);
        break;
      case 'TOGGLE_MODAL':
        state.modalOpen = data;
        break;
      case 'TOGGLE_SHARE_MODAL':
        state.shareModalOpen = data;
        break;
      case 'SET_UNITS':
        if (state.query.units === data) {
          return;
        }

        let query = state.query.clone();
        query.units = data;
        // TODO: Centralize setting query
        state.prevQuery = null;
        state.query = query;
        this.updateURL(state.query);

        break;
      case 'SET_ACTIVE_COUNTRY':
        state.activeCountry = data.country;
        state.mapMenuOpen = data !== null;
        state.mapMenuRect = data ? data.rect : null;

        break;

      case 'HIDE_COUNTRY_MENU':
        // XXX: Does this break showing the country info modal?
        state.activeCountry = null;
        state.hoveredCountry = null;
        state.mapMenuOpen = false;
        break;

      case 'SHOW_COUNTRY_MODAL':
        state.countryModalOpen = true;
        state.mapMenuOpen = false;
        break;

      case 'HIDE_COUNTRY_MODAL':
        state.countryModalOpen = false;
        state.activeCountry = null;
        break;

      case 'SHOW_NES_MODAL':
        state.nesModalOpen = true;
        state.mapMenuOpen = false;
        break;

      case 'HIDE_NES_MODAL':
        state.nesModalOpen = false;
        break;

      case 'RESULTS_LOADED':
        state.results[data.id] = {
          status: 'ready',
          // FIXME: Move this condition and make more generic
          data: data.id !== 'footprints' ? this.rehydrate(data.data) : data.data,
        };

        break;
      case 'SHOW_MAP':
        state.mapLoaded = true;
        break;

      case 'SET_HOVERED_FLOW':
        if (state.hoveredFlow === data.data) {
          return;
        }

        state.hoveredFlow = data.data;
        state.hoveredFlowPoint = data.point || null;

        if (data.data !== null) {
          // XXX: Close map menu
          state.activeCountry = null;
          state.mapMenuOpen = false;
        }

        break;

      // case 'SET_HOVERED_FLOW_POINT':
      //   state.hoveredFlowPoint = data || null;
      //   break;

      case 'SET_HOVERED_COUNTRY':
        if (state.hoveredCountry === data) {
          return;
        }

        state.hoveredCountry = data;

        if (data !== null && state.hoveredCountry !== state.activeCountry) {
          // XXX: Close map menu
          state.activeCountry = null;
          state.mapMenuOpen = false;
        }

        break;

      case 'SET_YEAR':
        if (data === state.query.year.id) {
          return;
        }

        let temp = state.query.clone();
        temp.year = _data.years.get(data);
        state.prevQuery = null;
        state.query = temp;
        this.updateURL(state.query);
        this.fetchData(state.query);
        break;

      case 'TOGGLE_QUERY_POPOVER':
        state.queryPopoverOpen = data;
        break;

      case 'SET_DOWNLOAD_PENDING':
        state.pendingDownload = data;
        break;

      case 'SET_SHOW_TOOLTIP':
        state.showTooltip = data;
        break;

      case 'SET_ZOOM_REGION':
        state.zoomRegion = data;
        break;

      case 'SET_AUTO_ZOOM':
        state.autoZoom = data;
        break;
    }

    this.notify();
  },

  notify: function () {
    if (this.notifyPending) {
      return;
    }

    this.notifyPending = true;

    requestAnimationFrame(() => {
      this.notifyPending = false;
      this._notify();
    });
  },

  _notify: function () {
    this.listeners.forEach(function (callback) {
      callback();
    });
  },

  updateURL: function (query, replaceState = false) {
    if (!window || !window.history || !window.history.pushState) {
      return;
    }

    const url = window.location.href.replace(/\?.*/, '') + '?' + query.toString();

    if (replaceState) {
      window.history.replaceState({}, document.title, url);
    } else {
      window.history.pushState({}, document.title, url);
    }
  },

  fetchData: function (query) {
    this.fetchResultsSet(query, 'trades', true);
    this.fetchResultsSet(query, 'commodities', true);
    this.fetchResultsSet(query, 'exporters', true);
    this.fetchResultsSet(query, 'importers', true);
    this.fetchResultsSet(query, 'historical', true);

    if (__config.hasFootprints) {
      this.fetchResultsSet(query, 'footprints', true);
    }

    this.delayedFetchResultsSet(200, query, 'totals', true);

    this.notify();
  },

  fetchResultsSet: function (query, setID, silent, retry) {
    if (this.delayedRequestTimeouts[setID]) {
      window.clearTimeout(this.delayedRequestTimeouts[setID]);
    }

    if (this.pendingRequests[setID]) {
      this.pendingRequests[setID].abort();
    }

    let request = new XMLHttpRequest();

    request.open('GET', `${__config.api_path}${setID}?` + query.toString(['units']), true);

    // TODO: Move to top level function
    request.onload = function () {
      this.pendingRequests[setID] = null;

      if (request.status >= 200 && request.status < 400) {
        this.dispatch('RESULTS_LOADED', {
          id: setID,
          data: JSON.parse(request.responseText),
        });
      } else if (request.status === 503 && !retry) {
        this.delayedFetchResultsSet(600, query, setID, silent);
      }
    }.bind(this);

    state.results[setID] = {
      status: 'pending',
      data: null,
    };

    this.pendingRequests[setID] = request;

    request.send();

    if (!silent) {
      this.notify();
    }
  },

  delayedFetchResultsSet: function (delay, query, setID, silent) {
    if (this.delayedRequestTimeouts[setID]) {
      window.clearTimeout(this.delayedRequestTimeouts[setID]);
    }

    this.delayedRequestTimeouts[setID] = window.setTimeout(
      function (_this) {
        _this.fetchResultsSet(query, setID, silent, true);

        if (!silent) {
          _this.notify();
        }
      },
      delay,
      this
    );
  },
};
