import React, { Component } from 'react';
import sortBy from 'lodash/sortBy';

import formatters from '../../formatters';
import ListBars from '../../components/list-bars/list-bars';
import ListSparklines from '../../components/list-sparklines/list-sparklines';
import IconButton from '../../components/icon-button/icon-button';
import DashboardTrendTitle from '../../components/dashboard-trend-title/dashboard-trend-title';
import {
  DashboardColumns,
  DashboardColumnHeader,
  DashboardColumnBody,
} from '../../components/DashboardColumns';

// TODO: Rename DashboardTraders
export default class DashboardCountries extends Component {
  shouldComponentUpdate(nextProps) {
    return (
      nextProps.data !== null &&
      (this.props.data !== nextProps.data || this.props.units !== nextProps.units)
    );
  }

  render(props) {
    if (props.data === null) {
      return <div className="dashboard__grid" />;
    }

    const top = sortBy(props.data.main, [props.units])
      .filter((datum) => {
        return datum[props.units] !== null;
      })
      .reverse()
      .slice(0, 5)
      .map(function (datum) {
        let value = datum[props.units];
        let formatter = props.units === 'value' ? formatters.currency : formatters.weight;

        return {
          label: datum[props.type].model.name,
          value: value,
          formattedValue: formatter(value),
          disabled: datum[props.type] === props.active,
          original: datum,
        };
      });

    let trendsPropName = `delta_${props.units}_pc`;
    let trends = sortBy(props.data.trends[props.units], [trendsPropName]);
    let trendsMapper = function (datum) {
      let value = datum[trendsPropName];

      return {
        label: datum[props.type].model.name,
        value: value,
        formattedValue: formatters.signedPercentage(value),
        historicalValues: datum[`agg_${props.units}`],
        disabled: datum[props.type] === props.active,
        original: datum,
      };
    };

    let declining = trends
      .slice(0, 5)
      .filter(function (datum) {
        return datum[trendsPropName] < 0;
      })
      .map(trendsMapper);

    let growing = trends
      .reverse()
      .slice(0, 5)
      .filter(function (datum) {
        return datum[trendsPropName] > 0;
      })
      .map(trendsMapper);

    let button;
    if (props.prevQuery) {
      button = (
        <IconButton icon="left" handleClick={this.handleButtonClick.bind(this, props.prevQuery)}>
          Back
        </IconButton>
      );
    }

    return (
      <DashboardColumns>
        <DashboardColumnHeader modifier="inline">
          <h1>Top 5</h1>
          {button}
        </DashboardColumnHeader>
        <DashboardColumnBody>
          <ListBars data={top} type={props.type} handleClick={props.handleClick} />
        </DashboardColumnBody>
        <DashboardColumnHeader modifier="inline">
          <h1>Fastest growing</h1>
          <DashboardTrendTitle years={props.data.trendYears} />
        </DashboardColumnHeader>
        <DashboardColumnBody>
          <ListSparklines data={growing} type={props.type} handleClick={props.handleClick} />
        </DashboardColumnBody>
        <DashboardColumnHeader modifier="inline">
          <h1>Fastest declining</h1>
          <DashboardTrendTitle years={props.data.trendYears} />
        </DashboardColumnHeader>
        <DashboardColumnBody>
          <ListSparklines data={declining} type={props.type} handleClick={props.handleClick} />
        </DashboardColumnBody>
      </DashboardColumns>
    );
  }

  handleButtonClick = (query) => {
    if (!this.props.handleBack) {
      return;
    }

    this.props.handleBack(query);
  };
}
