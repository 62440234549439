import React, { Component } from 'react';

// FIXME: Pass handlers rather than importing store
// (as we pass to the app via a prop)
// import store from '../../store';

import './_map-labels.scss';

export default class MapLabels extends Component {
    shouldComponentUpdate(nextProps, nextState) {
        return (
            nextProps.data !== this.props.data ||
            nextProps.countries !== this.props.countries ||
            nextProps.hoveredFlow !== this.props.hoveredFlow ||
            nextProps.hoveredCountry !== this.props.hoveredCountry ||
            nextProps.activeCountry !== this.props.activeCountry
        );
    }

    render (props, state) {
        let activeCountries = [];

        if (props.data && props.hoveredFlow) {
            props.data.forEach(function (datum) {
                if (datum.exporter === props.hoveredFlow.exporter &&
                    datum.importer === props.hoveredFlow.importer)
                {
                    activeCountries.push(datum.exporter, datum.importer);
                }
            });
        } else if (props.data && (props.hoveredCountry || props.activeCountry)) {
            let tempCountry = props.hoveredCountry || props.activeCountry;

            props.data.forEach(function (datum) {
                if (datum.exporter === tempCountry || datum.importer === tempCountry) {
                    if (activeCountries.indexOf(datum.exporter) < 0) {
                        activeCountries.push(datum.exporter);
                    }

                    if (activeCountries.indexOf(datum.importer) < 0) {
                        activeCountries.push(datum.importer);
                    }
                }
            });
        }

        let className = 'map__labels';
        if (activeCountries.length) {
            className += ' map__labels--hovered';
        }

        return (
            <g class={ className }>
                { props.countries.map(function (country) {
                    let className = 'map__label';
                    if (activeCountries.length && activeCountries.indexOf(country) >= 0) {
                        className += ' map__label--active';
                    }

                    return (
                        <use
                            class={ className }
                            xlinkHref={ `#label-${country.id}` }
                            onClick={ this.handleClick.bind(this, country) }
                            onMouseEnter={ this.handleMouseEnter.bind(this, country) }
                            onMouseLeave={ this.handleMouseLeave }
                        />
                    );
                }, this) }
            </g>
        )
    }

    handleClick (country, event) {
        event.preventDefault();

        if (!this.props.onClick) {
            return;
        }

        let node;
        if (typeof SVGElementInstance !== 'undefined' && event.target instanceof SVGElementInstance) {
            node = event.target.correspondingUseElement;
        } else {
            node = event.target;
        }

        let rect = node.getBoundingClientRect();

        this.props.onClick(country, {
            top: rect.top + window.pageYOffset,
            right: rect.right,
            bottom: rect.bottom + window.pageYOffset,
            left: rect.left
        });
    }

    handleMouseEnter = (country) => {
        if (!this.props.onHover) {
            return;
        }

        this.props.onHover(country);
    }

    handleMouseLeave = (country) => {
        if (!this.props.onHover) {
            return;
        }

        this.props.onHover(null);
    }
}
