import React, { Component } from 'react';
import { render } from 'react-dom';

import './_portal.scss';

export default class Portal extends Component {
    componentDidMount () {
        // TODO: Check if this can be done with ARIA attributes?
        let className = 'portal';
        if (this.props && this.props.modal) {
            className += ' portal--modal';
        }

        this.portalNode = document.createElement('div');
        this.portalNode.className = className;
        this.portalNode.setAttribute('aria-hidden', true);
        document.body.appendChild(this.portalNode);

        this.renderSubtree();
    }

    componentDidUnmount () {
        this.portalNode.parentNode.removeChild(this.portalNode);
    }

    componentDidUpdate (prevProps) {
        if (prevProps.open !== this.props.open) {
            if (this.props.modal) {
                this.toggleDocumentScrolling(this.props.open);
            }

            this.portalNode.setAttribute('aria-hidden', !this.props.open);
        }

        this.renderSubtree();
    }

    render (props) {
        return <div />;
    }

    renderSubtree (props) {
        props = props || this.props;

        let subtree;
        if (!props.open) {
            subtree = <div />;
        } else {
            subtree = <div>{ props.children }</div>;
        }

        render(subtree, this.portalNode, this.portalNode.lastElementChild);
    }

    toggleDocumentScrolling (disable) {
        let className = document.documentElement.className;
        className = className.replace(/ ?u-disablescrolling/, '');
        if (disable) {
            className += ' u-disablescrolling';
        }

        document.documentElement.className = className;
    }
}
