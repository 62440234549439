import React, { Component } from 'react';
import { scaleLinear, scalePoint } from 'd3-scale';
import { line } from 'd3-shape';
import range from 'lodash/range';

import List from '../list/list';
import Sparkline from '../Sparkline';

export default class ListSparklines extends List {
    render (props) {
        if (!props.data.length) {
            return this.renderEmpty();
        }

        return (
            <ol className="list">
                { props.data.map(function (datum, i) {
                    let className = 'list__item';
                    if (datum.disabled) {
                        className += ' list__item--disabled';
                    }

                    return (
                        <li
                            class={ className }
                            onClick={ this.handleClick.bind(this, datum) }
                            onMouseEnter={ this.handleMouseEnter.bind(this, datum) }
                            onMouseLeave={ this.handleMouseLeave }
                        >
                            <div className="list__row">
                                <div className="list__cell list__cell--counter">
                                    { i + 1 }
                                </div>
                                <div className="list__cell list__cell--title" title={ datum.label }>
                                    <span>{ datum.label }</span>
                                </div>
                                <div className="list__cell list__cell--sparkline">
                                    { datum.historicalValues && <Sparkline data={datum.historicalValues} type={props.type} /> }
                                </div>
                                <div className="list__cell list__cell--value">
                                    { datum.formattedValue }
                                </div>
                            </div>
                        </li>
                    );
                }, this) }
            </ol>
        );
    }
}
