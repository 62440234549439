import React, { Component } from 'react';
import Vector from 'victor';

import './styles.scss';

export default function MapFlows (props) {
    const a = new Vector(props.a.x, props.a.y);
    const b = new Vector(props.b.x, props.b.y);
    const angle = Math.round(b.subtract(a).horizontalAngleDeg());

    return (
      <linearGradient
        id={`gradient-${props.id}`}
        gradientTransform={`rotate(${angle}, 0.5, 0.5)`}
        x1="0"
        y1="0"
        x2="1"
        y2="0"
        spreadMethod="pad"
      >
        <stop offset="20%" className="map-def-gradient__start-color" />
        <stop offset="100%" className="map-def-gradient__end-color" />
      </linearGradient>
    );
}
