import React from 'react';

import formatters from '../../formatters';

import './styles.scss';

export default function CountryInfoTable(props) {
  if (!props.rows) {
    return;
  }

  return (
    <table className="countryinfotable">
      <thead>
        <tr>
          <td>Indicator</td>
          {/* TODO: Research accessible footnotes */}
          <td className="countryinfotable__head__rank">Percentile Rank*</td>
          <td className="countryinfotable__head__value">Value</td>
        </tr>
      </thead>
      <tbody>
        {props.rows.map((row) => {
          return (
            <>
              <tr className="countryinfotable__row countryinfotable__row--bar" aria-hidden="true">
                <td colspan="2" className="countryinfotable__cell__bar">
                  <div className="countryinfotable__bar">
                    {row.rank ? <div style={{ width: `${row.rank}%` }} /> : null}
                  </div>
                </td>
                <td className="countryinfotable__cell__value">
                  <div />
                </td>
              </tr>
              <tr className="countryinfotable__row">
                <td className="countryinfotable__cell__indicator">
                  <div title={row.tooltip ? row.tooltip : null}>{row.label}</div>
                </td>
                <td className="countryinfotable__cell__rank">
                  <div>{formatters.null(row.rank)}</div>
                </td>
                <td className="countryinfotable__cell__value">
                  <div>{row.value}</div>
                </td>
              </tr>
            </>
          );
        })}
      </tbody>
    </table>
  );
}
