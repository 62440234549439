import React from 'react';

import formatters from '../../formatters';

import './_map-counter.scss';

export default function MapCounter (props) {
    const count = formatters.thousands(props.count);
    const total = formatters.thousands(props.total);

    return <>Displaying {count} of {total} flows</>;

    // return (
    //     <div className="map-counter">
    //         <p>{ `Displaying ${count} of ${total} flows` }</p>
    //     </div>
    // )
}
