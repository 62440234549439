import React, { Component } from 'react';

import './_map-hitboxes.scss';

export default class MapHitboxes extends Component {
    render (props) {
        return (
            <g className="map__hitboxes">
                { props.data.map(function (datum) {
                    return (
                        <use
                            xlinkHref={ `#flow-${datum.exporter.id}-${datum.importer.id}` }
                            onMouseEnter={ this.handleMouseEnter.bind(this, datum) }
                            onMouseLeave={ this.handleMouseLeave }
                        />
                    );
                }, this) }
            </g>
        )
    }

    handleMouseEnter = (data, event) => {
        if (!this.props.onHover) {
            return;
        }

        let point = {
            left: event.pageX,
            top: event.pageY
        };

        this.props.onHover(data.original, point);
    }

    handleMouseLeave = (event) => {
        if (!this.props.onHover) {
            return;
        }

        // If moving to another flow don't clear hover state
        if (event.relatedTarget &&
            event.relatedTarget.nodeName.toLowerCase() === 'use' &&
            (this.base && this.base.contains(event.relatedTarget)))
        {
            return;
        }

        this.props.onHover(null);
    }
}
