import React, { Component } from 'react';

import Icon from '../icon/icon';

import './_data-warning.scss';

export default class DataWarning extends Component {
    render (props) {
        let messages = [];

        // Data isn't loaded so we don't know if its empty
        if (!props.data) {
            return;
        }

        const data = props.data.filter((datum) => {
            return datum[props.query.units] !== null;
        });

        if (!data.length) {
            messages.push('No data available');
        }

        if (props.query.exporter &&
            (props.query.year.id < props.query.exporter.model.start_year ||
            props.query.year.id > props.query.exporter.model.end_year))
        {
            let startYear = props.query.exporter.model.start_year;
            let endYear = props.query.exporter.model.end_year;
            messages.push(`Data for ${props.query.exporter.model.name} is only available between ${startYear} and ${endYear}`);
        }

        if (props.query.importer &&
            (props.query.year.id < props.query.importer.model.start_year ||
            props.query.year.id > props.query.importer.model.end_year))
        {
            let startYear = props.query.importer.model.start_year;
            let endYear = props.query.importer.model.end_year;
            messages.push(`Data for ${props.query.importer.model.name} is only available between ${startYear} and ${endYear}`);
        }

        if (!messages.length) {
            return;
        }

        return (
            <div className="datawarning">
                <div className="datawarning__inner">
                    <div className="datawarning__messages">
                        <div className="datawarning__messages__inner">
                            { messages.map(function (message) {
                                return (
                                    <p>
                                        <Icon id="error" />
                                        { message }
                                    </p>
                                );
                            }) }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
