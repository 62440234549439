'use strict';

import React, { Component } from 'react';
import { voronoi } from 'd3-voronoi';
import { forceSimulation } from 'd3-force';
import { bboxCollide } from 'd3-bboxCollide';
import keyBy from 'lodash/keyBy';

import Flow from './map-def-flow';
import Gradient from './map-def-gradient';

import { measureText } from '../../utilities/measureText.js';
import { transformPoint, scalePoint } from '../../utilities/geometry.js';
import { ZOOM_ANTIMERIDIAN_COUNTRIES } from '../../constants.js';

const FONT_SIZE = 14;
const DEFAULT_PADDING = 4;
const BOXED_PADDING = 8;

export default class MapDefs extends Component {
    constructor () {
        super();
    }

    shouldComponentUpdate (nextProps) {
        return (
            nextProps.data !== this.props.data ||
            nextProps.countries !== this.props.countries ||
            nextProps.projection !== this.props.projection ||
            nextProps.scale !== this.props.scale ||
            nextProps.width !== this.props.width ||
            nextProps.height !== this.props.height ||
            nextProps.transform !== this.props.transform
        );
    }

    render (props) {
        console.log('render map defs');

        if (console.time) {
            console.time('layout');
        }

        let labels = this.createLabels(props.countries, props.projection, props.width, props.wrapZoomCountries);

        const scale = props.transform ? props.transform.scale : 1;

        this.measureVoronoiCellOverflow(labels, props.width * scale, props.height * scale);
        console.log(labels);
        this.layoutLabels(labels, props.width * scale, props.height * scale);

        if (console.time) {
            console.timeEnd('layout');
        }

        let labelsById = keyBy(labels, 'id');

        let flows = [];
        let gradients = [];

        if (props.scale) {
            props.data.forEach(function (datum) {
                let a = labelsById[datum.exporter.id];
                let b = labelsById[datum.importer.id];

                if (!a || !b) {
                    return;
                }

                let id = `${datum.exporter.id}-${datum.importer.id}`;
                let thickness = props.scale(datum.value);

                gradients.push(
                    <Gradient key={ id } id={ id } a={ a } b={ b } />
                );

                flows.push(
                    <Flow key={ id } id={ id } a={ a } b={ b } thickness={ thickness } />
                );
            });
        }

        return (
            <defs>
                <marker
                    id="arrowhead-map"
                    viewBox="0 0 10 10"
                    refX="10"
                    refY="5"
                    markerWidth="5"
                    markerHeight="5"
                    orient="auto"
                    markerUnits="userSpaceOnUse"
                >
                    <path d="M0,0L10,5L0,10" className="map__arrowhead"></path>
                </marker>
                { labels.map(function (label) {
                    // let boxWidth = label.rect.width + 4;
                    // let boxHeight = label.rect.height + 4;
                    let boxWidth = label.rect.width;
                    let boxHeight = label.rect.height;
                    let boxClassName = 'map__label__box' + (label.boxed ? ' map__label__box--filled' : '');

                    let tspans = label.text.map(function (span, i) {
                        let dy = 0;
                        if (i === 0 && label.text.length > 1) {
                            dy = -0.5;
                        } else if (i > 0) {
                            dy = 1;
                        }

                        return <tspan x="0" dy={ `${dy}em` }>{ span }</tspan>;
                    });

                    return (
                        <g
                            id={ `label-${label.id}` }
                            transform={ `translate(${label.x} ${label.y})` }
                        >
                            <rect
                                class={ boxClassName }
                                x={ -boxWidth / 2 }
                                y={ -boxHeight / 2 }
                                width={ boxWidth }
                                height={ boxHeight }
                            />
                            <text className="map__label__text map__label__text--stroked" y="0.35em">
                                { tspans }
                            </text>
                            <text className="map__label__text" y="0.35em">
                                { tspans }
                            </text>
                        </g>
                    );
                }) }
                <g>{ gradients }</g>
                <g>{ flows }</g>
            </defs>
        );
    }

    createLabels (countries, projection, canvasWidth, wrapZoomCountries) {
        let _this = this;

        // let nesPoint = [-30, -50];

        let unpositionedLabels = [];

        let labels = countries.reduce(function (memo, country) {
            // if (!country.model.lng || !country.model.lat) {
            //     return memo;
            // }

            let x = country.model.lng;
            let y = country.model.lat;

            let positioned = (x !== null && y !== null);

            let point = positioned ? transformPoint(projection([x, y]), this.props.transform) : null;

            let moved = false;
            if (wrapZoomCountries && point && ZOOM_ANTIMERIDIAN_COUNTRIES.includes(country.model.id)) {
              point = projection([x, y]);
              point = transformPoint([point[0] + canvasWidth, point[1]], this.props.transform);
              console.log('move country label', country.model.name, point);
              moved = true;
            }

            let text = country.model.map_name || country.model.name;
            text = text.split('\\n');

            let label = {
                id: country.id,
                x: point ? point[0] : null,
                y: point ? point[1] : null,
                originX: point ? point[0] : null,
                originY: point ? point[1] : null,
                text: text,
                shortText: country.model.map_short_name,
                rect: measureText(text, FONT_SIZE, country.model.is_nes ? BOXED_PADDING : DEFAULT_PADDING),
                boxed: country.model.is_nes,
                moved: moved
            };

            memo.push(label);

            if (!positioned) {
                unpositionedLabels.push(label);
            }

            return memo;
        }.bind(this), []);

        // Place currently unpositioned labels along bottom of map
        let labelWidth = 30;
        let labelLatitude = 0;
        labelLatitude -= (labelWidth * (unpositionedLabels.length - 1)) / 2;

        unpositionedLabels.forEach(function (label, i) {
            let latitude = labelLatitude + (labelWidth * i);
            let point = transformPoint(projection([latitude, -50]), this.props.transform);

            label.x = label.originX = point[0];
            label.y = label.originY = point[1];
        }, this);

        return labels;
    }

    measureVoronoiCellOverflow (labels, width, height) {
        let layout = voronoi()
            .x(function (datum) {
                return datum.x
            })
            .y(function (datum) {
                return datum.y
            })
            .extent([[0, 0], [width, height]]);

        let polygons = layout.polygons(labels);

        polygons.forEach(function (polygon) {
            let minX = null;
            let maxX = null;
            let minY = null;
            let maxY = null;

            polygon.forEach(function (point) {
                if (minX === null || point[0] < minX) {
                    minX = point[0];
                }

                if (maxX === null || point[0] > maxX) {
                    maxX = point[0];
                }

                if (minY === null || point[1] < minY) {
                    minY = point[1];
                }

                if (maxY === null || point[1] > maxY) {
                    maxY = point[1];
                }
            });

            let w = maxX - minX;
            let h = maxY - minY;

            let overflowing = (
                polygon.data.rect.width > w ||
                polygon.data.rect.height > h
            );

            if (overflowing && polygon.data.shortText) {
                polygon.data.text = polygon.data.shortText.split('\\n');
                polygon.data.rect = measureText(polygon.data.text, 12, polygon.data.boxed ? BOXED_PADDING : DEFAULT_PADDING)
            }

            polygon.data.overflow = {
                x: w / polygon.data.rect.width,
                y: h / polygon.data.rect.height,
            };

            // If label at origin overflows container width move origin to accomodate
            if (polygon.data.originX + polygon.data.rect.hwidth > width && !polygon.data.moved) {
                polygon.data.originX -= (polygon.data.originX + polygon.data.rect.hwidth) - width;
            }
        }, this);
    }

    layoutLabels (labels, width, height) {
        let originForce = function () {
            let nodes = [];
            let nodesLength = 0;

            let force = function (alpha) {
                for (var i = 0; i < nodesLength; i ++) {
                    let node = nodes[i];
                    // FIXME: Is 1 the right default for overflow?
                    const overflow = node.overflow || { x: 1, y: 1 };
                    let kx = alpha * (1 / overflow.x);
                    let ky = alpha * (1 / overflow.y);
                    node.vx += (node.originX - node.x) * kx;
                    node.vy += (node.originY - node.y) * ky;
                }
            }

            force.initialize = function (_) {
                nodes = _;
                nodesLength = _.length;
            }

            return force;
        }

        // Constrain to bounding box on x-axis
        let constrainForce = function () {
            let nodes = [];
            let nodesLength = 0;

            let force = function (alpha) {
                for (var i = 0; i < nodesLength; i ++) {
                    let node = nodes[i];
                    if (node.x - node.rect.hwidth < 0) {
                        node.x = node.rect.hwidth + 8;
                    } else if (node.x + node.rect.hwidth > width && !node.moved) {
                        node.x = width - (node.rect.hwidth + 8);
                        // node.x = node.fx = width - node.rect.hwidth;
                    }
                }
            }

            force.initialize = function (_) {
                nodes = _;
                nodesLength = _.length;
            }

            return force;
        }

        let collideForce = bboxCollide(function (node, i) {
            return [
                [-node.rect.hwidth, -node.rect.hheight],
                [node.rect.hwidth, node.rect.hheight]
            ];
        })
            // .strength(0.2).iterations(3);
            .strength(0.4).iterations(6);

        let simulation = forceSimulation(labels)
            .force('origin', originForce())
            .force('collide', collideForce)
            .force('constrain', constrainForce())
            .stop();

        // TODO: Find source of this snippet
        // let iterations = Math.ceil(
        //     Math.log(simulation.alphaMin()) /
        //     Math.log(1 - simulation.alphaDecay())
        // );

        let iterations = 10;

        console.log(`${iterations} layout iterations`);

        // XXX: This will hang the main thread
        for (let i = 0; i < iterations; i++) {
            simulation.tick();
        }
    }
}
