import { ZOOM_GROUPS_EXCLUDED_COUNTRIES } from '../../constants.js';

export function filterZoomGroupsExcludedCountries(groupId, countries) {
  if (!ZOOM_GROUPS_EXCLUDED_COUNTRIES[groupId]) {
    return countries;
  }

  return countries.filter((country) => {
    return !ZOOM_GROUPS_EXCLUDED_COUNTRIES[groupId].includes(country.model.id);
  });
}
