import React, { Component } from 'react';

import formatters from '../../formatters';
import Icon from '../icon/icon';
import Chart from '../footprints-chart/footprints-chart';
import {
  DashboardColumns,
  DashboardColumnHeader,
  DashboardColumnBody,
} from '../../components/DashboardColumns';

import './_dashboard-footprints.scss';

const ICON_MAP = {
  land: 'forestry',
};

export default class DashboardFootprints extends Component {
  render(props) {
    if (!props.data || !props.data.content || !props.data.content.columns.length) {
      return;
    }

    return (
      <div className="dashboardfootprints">
        <DashboardColumns>
          <DashboardColumnHeader modifier="full">
            <h1>{props.data.content.title}</h1>
          </DashboardColumnHeader>
          {this.renderContent(props.data)}
        </DashboardColumns>
      </div>
    );
  }

  renderContent(data) {
    if (!data || !data.content || !data.content.columns.length) {
      return;
    }

    let columns = data.content.columns;
    let nodes = [];

    nodes.push(this.renderColumn(columns[0]));

    switch (data.content.template) {
      case 1:
        nodes.push(
          <DashboardColumnBody>
            <div className="dashboardfootprints__chart">
              <Chart
                data={data.content.chart}
                formatter={formatters.weight}
                legendPosition="right"
              />
            </div>
          </DashboardColumnBody>
        );
        break;
      case 2:
        nodes.push(this.renderColumn(columns[1]));
        nodes.push(
          <DashboardColumnBody>
            <div className="dashboardfootprints__chart">
              <Chart data={data.content.chart} formatter={formatters.weight} />
            </div>
          </DashboardColumnBody>
        );
        break;
      case 3:
      default:
        nodes.push(this.renderColumn(columns[1]));
        nodes.push(this.renderColumn(columns[2]));
        break;
    }

    return nodes;
  }

  renderColumn(data) {
    if (!data || !data.content) {
      return null;
    }

    const iconId = ICON_MAP[data.icon] ? ICON_MAP[data.icon] : data.icon;

    return (
      <DashboardColumnBody>
        <div class={`dashboardfootprints__icon dashboardfootprints__icon--${iconId}`}>
          <Icon id={iconId} />
        </div>
        <p>{data.content}</p>
      </DashboardColumnBody>
    );
  }
}
