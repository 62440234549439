import React, { Component } from 'react';

import './_map-menu.scss';

// TODO: Rename to CountryMenu?
export default class MapMenu extends Component {
    render (props, state) {
        return (
            <div className="mapmenu">
                <h1 className="mapmenu__header">{ props.country.model.name }</h1>
                <ul className="mapmenu__body">
                    <li className="mapmenu__item">
                        { this.renderInfoButton(props.country) }
                    </li>
                    { this.renderItems(props.country, props.query) }
                </ul>
            </div>
        );
    }

    renderInfoButton (country) {
        if (country.model.is_nes) {
            return (
                <button
                    type="button"
                    onClick={ this.handleNESProfileClick }
                >
                    What is this?
                </button>
            );
        } else {
            return (
                <button
                    type="button"
                    disabled={ !country.model.has_meta }
                    onClick={ this.handleProfileClick }
                >
                    Country profile
                </button>
            );
        }
    }

    renderItems (country, query) {
        let countryIsExporter = (country.model === query.exporter.model);
        let countryIsImporter = (country.model === query.importer.model);

        if (countryIsExporter) {
            return [
                this.renderItem('Deselect as exporter', 'exporter'),
                this.renderItem('Swap to importer', 'importer', this.handleCountryTransposeClick)
            ];
        } else if (countryIsImporter) {
            return [
                this.renderItem('Deselect as importer', 'importer'),
                this.renderItem('Swap to exporter', 'exporter', this.handleCountryTransposeClick)
            ];
        } else {
            return [
                this.renderItem('Select as exporter', 'exporter'),
                this.renderItem('Select as importer', 'importer')
            ];
        }
    }

    renderItem (label, type, clickHandler) {
        if (!clickHandler) {
            clickHandler = this.handleCountryClick;
        }

        return (
            <li className="mapmenu__item">
                <button
                    type="button"
                    onClick={ clickHandler.bind(this, type) }
                >
                    { label }
                </button>
            </li>
        )
    }

    handleProfileClick = () => {
        if (this.props.onProfileClick) {
            this.props.onProfileClick(this.props.country);
        }
    }

    handleNESProfileClick = () => {
        if (this.props.onNESProfileClick) {
            this.props.onNESProfileClick();
        }
    }

    handleCountryClick = (type) => {
        if (this.props.onCountryClick) {
            this.props.onCountryClick(type, this.props.country);
        }
    }

    handleCountryTransposeClick = (type, event) => {
        if (this.props.onCountryTransposeClick) {
            this.props.onCountryTransposeClick(type, this.props.country);
        }
    }
}
