import React, { Component } from 'react';

// TDDO: Remove radio component or move inside?
import Radio from '../radio/radio';

import './_units.scss';

export default class Units extends Component {
  render(props) {
    const className = ['units'];

    return (
      <div class={className.join(' ')}>
        <h1 className="units__header">{__('trade-map-units')}</h1>
        {/* FIXME: Change event may not propagate in IE9 */}
        {/* TODO: Add fieldset */}
        <form className="units__form" onChange={this.handleChange}>
          <Radio
            label={__('trade-map-units-value')}
            name="units"
            value="value"
            current={props.current}
          />
          {!props.disableWeight ? (
            <Radio
              label={__('trade-map-units-weight')}
              name="units"
              value="weight"
              current={props.current}
            />
          ) : null}
        </form>
      </div>
    );
  }

  handleChange = (event) => {
    this.props.handleChange(event.target.value);
  };
}
