import React, { Component } from 'react';

import Icon from '../icon/icon';

import './_query-transpose.scss';

export default class QueryTranspose extends Component {
    render (props) {
        return (
            <div className="querytranspose">
                <button
                    type="button"
                    title={ props.tooltip }
                    onClick={ this.handleClick.bind(this) }
                >
                    <Icon id="swap-trade" />
                </button>
            </div>
        );
    }

    handleClick (e) {
        this.props.handleClick();
    }
}
