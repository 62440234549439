import React, { Component } from 'react';
import { scaleLinear, scalePoint } from 'd3-scale';
import { line } from 'd3-shape';

import './_footprints-chart.scss';

let viewbox = { w: 200, h: 100 };

// TODO: Move to CSS?
let defaultColours = [
    '#afbd21',
    '#b32017',
    '#81cec1',
    '#e4701e',
    '#8a2529',
    '#4d917b',
    '#d5a00f',
    '#949c50',
    '#917578',
    '#a78462'
];

export default class FootprintsChart extends Component {
    render (props, state) {
        let domainX = [];
        let maxY = null;

        let colours = defaultColours;
        if (props.colours) {
            colours = props.colours;
        }

        let data = [];

        props.data.forEach(function (series, i) {
            series.data.forEach(function (datum) {
                if (i === 0) {
                    domainX.push(datum.year);
                }

                maxY = Math.max(maxY, datum.value);
            });

            let temp = {
                id: series.id || series.name.toLowerCase(),
                name: series.name,
                data: series.data
            };

            if (props.order && props.order.indexOf(temp.id) !== -1) {
                let index = props.order.indexOf(temp.id);
                temp.colour = colours[index];
                data[index] = temp;
            } else {
                temp.colour = colours[i];
                data.push(temp);
            }
        });

        let scaleX = scalePoint()
            .domain(domainX)
            .range([0, 200]);

        let scaleY = scaleLinear()
            .domain([0, maxY])
            .range([100, 0])
            .nice();

        let lineGenerator = line()
            .x(function (datum) {
                return scaleX(datum.year);
            })
            .y(function (datum) {
                return scaleY(datum.value);
            })
            .defined(function (datum) {
                return (datum.value !== null);
            });

        return (
            <div className={`footprintschart ${props.modifier ? `footprintschart--${props.modifier}` : null}`}>
                <div className="footprintschart__body">
                    <div className="footprintschart__canvaswrapper">
                        <svg className="footprintschart__canvas" viewBox={ `0 0 ${viewbox.w} ${viewbox.h}` } preserveAspectRatio="none">
                            { data.map(function (series) {
                                return <path d={ lineGenerator(series.data) } style={{ stroke: series.colour }} />
                            }) }
                        </svg>
                    </div>
                    <div className="footprintschart__xaxis">
                        <div className="footprintschart__xaxis__ticks">
                            { this.renderXAxis(domainX, scaleX, 5) }
                        </div>
                    </div>
                    <div className="footprintschart__yaxis">
                        { this.renderYAxis(scaleY, props.formatter) }
                    </div>
                </div>
                <div class={ 'footprintschart__legend' + (props.legendPosition ? ` footprintschart__legend--${props.legendPosition}` : '') }>
                    <ul>
                        { data.map(function (series) {
                            return (
                                <li className="footprintschart__legend__item">
                                    <span style={{ backgroundColor: series.colour }}></span>
                                    { series.name }
                                </li>
                            );
                        }) }
                    </ul>
                </div>
            </div>
        );
    }

    renderXAxis (data, scale, interval) {
        return data.reduce(function (memo, datum, i) {
            const mod = datum % interval;
            const draw = (i === 0 && mod > 2) || (i === data.length - 1 && mod > 2) || !mod;

            if (!draw) {
                return memo;
            }

            let x = scale(datum) / 2;

            memo.push(
                <div className="linechart__xaxis__tick" style={{ left: `${x}%` }}>
                    <span>{ datum }</span>
                </div>
            );

            return memo;
        }, []);
    }

    renderYAxis (scale, formatter) {
        let max = scale.domain()[1];

        return (
            <div>
                <div className="linechart__yaxis__tick">
                    <span>{ formatter(max) }</span>
                </div>
                <div className="linechart__yaxis__tick" style={{ top: '25%' }}>
                    <span>{ formatter(max * 0.75) }</span>
                </div>
                <div className="linechart__yaxis__tick" style={{ top: '50%' }}>
                    <span>{ formatter(max * 0.5) }</span>
                </div>
                <div className="linechart__yaxis__tick" style={{ top: '75%' }}>
                    <span>{ formatter(max * 0.25) }</span>
                </div>
                <div className="linechart__yaxis__tick" style={{ top: '100%' }}>
                    <span>{ formatter(0) }</span>
                </div>
            </div>
        );
    }
}
