import React, { Component } from 'react';

import Icon from '../icon/icon';

import './_modal.scss';

export default class Modal extends Component {
    componentWillMount () {
        window.addEventListener('resize', this.handleWindowResize);
    }

    componentWillUnmount () {
        window.removeEventListener('resize', this.handleWindowResize);
    }

    render (props) {
        return (
            <div className="modal" onClick={ this.handleClick }>
                <div className="modal__inner">
                    <div className="modal__main" onClick={ this.handleClickMain }>
                        <button
                            className="modal__close"
                            type="button"
                            aria-label="Close modal"
                            onClick={ this.handleCloseClick }
                        >
                            <Icon id="close" />
                        </button>
                        { props.children }
                    </div>
                </div>
            </div>
        );
    }

    // TODO: Only close if breakpoint is changed
    handleWindowResize = (event) => {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    handleClickMain = (event) => {
        event.stopPropagation();
    }

    handleClick = (event) => {
        if (!this.props.onClose) {
            return;
        }

        this.props.onClose();
    }

    handleCloseClick = () => {
        if (!this.props.onClose) {
            return;
        }

        this.props.onClose();
    }
}
