import { LEGACY_COUNTRYGROUPS_MAP } from '../constants.js';

export function replaceLegacyEconomyIds(params) {
  const newParams = { ...params };
  let dirty = false;

  if (params.exporter && LEGACY_COUNTRYGROUPS_MAP[params.exporter]) {
    newParams.exporter = LEGACY_COUNTRYGROUPS_MAP[params.exporter];
    dirty = true;
  }

  if (params.importer && LEGACY_COUNTRYGROUPS_MAP[params.importer]) {
    newParams.importer = LEGACY_COUNTRYGROUPS_MAP[params.importer];
    dirty = true;
  }

  return [newParams, dirty];
}
