export default class Query {
  constructor(params, data) {
    if (!params) {
      if (Query.data) {
        this.year = Query.data.years.getLast();
        this.exporter = Query.data.countries.getDefault();
        this.importer = Query.data.countries.getDefault();
        this.category = Query.data.categories.getDefault();
        this.units = 'value';
        this.autozoom = true;
      }

      return this;
    }

    this.parseParams(params, data);
  }

  parseParams(params, data) {
    if (params.year) {
      this.year = data.years.get(params.year);
    } else {
      this.year = data.years.getLast();
    }

    if (params.exporter) {
      if (isFinite(params.exporter)) {
        this.exporter = data.countries.get(params.exporter);
      } else {
        this.exporter = data.countryGroups.get(params.exporter);
      }
    } else {
      this.exporter = data.countries.getDefault();
    }

    if (params.importer) {
      if (isFinite(params.importer)) {
        this.importer = data.countries.get(params.importer);
      } else {
        this.importer = data.countryGroups.get(params.importer);
      }
    } else {
      this.importer = data.countries.getDefault();
    }

    if (params.category) {
      if (isFinite(params.category)) {
        this.category = data.categories.get(params.category);
      } else {
        this.category = data.categoryGroups.get(params.category);
      }
    } else {
      this.category = data.categories.getDefault();
    }

    if (params.units) {
      this.units = params.units;
    } else {
      this.units = 'value';
    }

    // Auto zoom should be on by default, but possible to turn off
    this.autozoom = params.autozoom === undefined ? true : !!parseInt(params.autozoom, 10);
  }

  set(prop, value) {
    this[prop] = value;
  }

  clone() {
    let clone = new Query();

    clone.year = this.year;
    clone.exporter = this.exporter;
    clone.importer = this.importer;
    clone.category = this.category;
    clone.units = this.units;
    clone.autozoom = this.autozoom;

    return clone;
  }

  equals(query) {
    if (this.year !== query.year) {
      return false;
    }

    if (this.exporter !== query.exporter) {
      return false;
    }

    if (this.importer !== query.importer) {
      return false;
    }

    if (this.category !== query.category) {
      return false;
    }

    // TODO: Compare units?

    return true;
  }

  toString(exclude) {
    let strings = [];

    strings.push('year=' + this.year.id);

    if (this.exporter && this.exporter.id) {
      strings.push('exporter=' + this.exporter.id);
    }

    if (this.importer && this.importer.id) {
      strings.push('importer=' + this.importer.id);
    }

    if (this.category && this.category.id) {
      strings.push('category=' + this.category.id);
    }

    if (!exclude || exclude.indexOf('units') < 0) {
      strings.push('units=' + this.units);
    }

    strings.push(`autozoom=${this.autozoom ? 1 : 0}`);

    return strings.join('&');
  }
}
