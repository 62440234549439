import React, { Component } from 'react';

import './_popover.scss';

export default class Popover extends Component {
    constructor () {
        super();

        this.state = {
            width: null
        };
    }

    componentWillMount () {
        document.addEventListener('mousedown', this.handleDocumentMouseDown);
        document.addEventListener('touchstart', this.handleDocumentMouseDown);

        window.addEventListener('resize', this.handleWindowResize);
    }

    componentWillUnmount () {
        document.removeEventListener('mousedown', this.handleDocumentMouseDown);
        document.removeEventListener('touchstart', this.handleDocumentMouseDown);

        window.removeEventListener('resize', this.handleWindowResize);
    }

    componentDidMount () {
        this.measure();
    }

    componentDidUpdate () {
        this.measure();
    }

    render (props, state) {
        let style;
        if (state.width) {
            let x = props.rect.left;
            // FIXME: Try to set 8px margin using CSS?
            let d = window.innerWidth - (x + state.width + 8);
            if (d < 0) {
                if (props.rect.right) {
                    x = props.rect.right - state.width;
                } else {
                    x += d;
                }
            }

            style = { left: `${x}px`, top: `${props.rect.bottom}px` };
        } else {
            style = { visibility: 'hidden' };
        }

        return (
            <div className="popover" style={ style }>
                { props.children }
            </div>
        );
    }

    handleDocumentMouseDown = (event) => {
        if (this.base && this.base.contains(event.target)) {
            return;
        }

        if (!this.props.onClose) {
            return;
        }

        this.props.onClose();
    }

    handleWindowResize = () => {
        if (!this.props.onClose) {
            return;
        }

        this.props.onClose();
    }

    measure () {
        let rect = this.base.getBoundingClientRect();

        if (rect.width === this.state.width) {
            return;
        }

        this.setState({ width: rect.width });
    }
}
