import React, { Component } from 'react';

import DownloadButton from './button.js';

import { fetch } from '../../helpers';

class DownloadRequest {
    constructor (query, onComplete) {
        this.job = null;
        this.pingTimeout = null;
        this.pingCount = 0;
        this.onComplete = onComplete;

        this.init(query);
    }

    init (query) {
        const url = `${ __config.api_path }downloads?${query.toString()}`;

        fetch(url, (data) => {
            this.job = data.job;
            this.ping();
        });
    }

    ping () {
        const delay = 500 + (Math.sqrt(this.pingCount * 2) * 500);

        this.pingTimeout = window.setTimeout(() => {
            const url = `${ __config.api_path }downloads/${this.job}`;

            fetch(url, (data) => {
                if (data.status === 'completed' && data.filename) {
                    this.trigger(data.filename, data.url);
                    return;
                }

                this.ping();
            });
        }, delay);

        this.pingCount ++;
    }

    trigger (filename, url) {
        if (!url) {
            url = `/temp/${filename}`;
        }

        this.onComplete();

        window.location.href = url;
    }

    cancel () {
        console.log('Download cancelled');

        window.clearTimeout(this.pingTimeout);

        this.onComplete();
    }
}

export default class Download extends Component {
    constructor () {
        super();

        this.state = {
            status: 'uninitialized'
        };
    }

    render (props, state) {
        return (
            <div className="download">
                <DownloadButton state={state.status} onClick={this.handleClick} />
            </div>
        );
    }

    handleClick = () => {
        const request = new DownloadRequest(this.props.query, () => {
            this.setState({ status: 'uninitialized' });

            if (this.props.handleComplete) {
                this.props.handleComplete();
            }
        });

        this.setState({ status: 'pending' });

        if (this.props.handleStart) {
            this.props.handleStart(request);
        }
    }

    handleWindowUnload = (event) => {
        if (this.state.status !== 'pending') {
            return;
        }

        // Dialog message cannot be customized in most browsers
        event.returnValue = true;
        return true;
    }
}
