import React from 'react';

import './_map-legend.scss';

export default function MapLegend (props) {
    const max = props.scale.domain()[1];
    const values = [max / 4, max / 2, max];

    return (
        <div className="map-legend">
            <h1>Scale</h1>
            <svg width="150" height="88">
                <defs>
                    <linearGradient id="gradient-legend" x1="0" y1="0" x2="1" y2="0" spreadMethod="pad">
                        <stop className="gradient-legend__exports" offset="25%"  />
                        <stop className="gradient-legend__imports" offset="100%" />
                    </linearGradient>
                    <marker id="arrowhead-legend" viewBox="0 0 10 10" refX="10" refY="5" markerWidth="5" markerHeight="5" orient="auto">
                        <path d="M0,0L10,5L0,10" className="map__arrowhead"></path>
                    </marker>
                </defs>
                <line className="map-legend__edge" x1="0" y1="0" x2="0" y2="84" />
                <line className="map-legend__edge" x1="80" y1="0" x2="80" y2="84" />
                <text className="map-legend__tics" x="4" y="84" dy="0">Exporter</text>
                <text className="map-legend__tics" x="84" y="84" dy="0">Importer</text>
                {
                    values.map(function (value, i) {
                        const transformY = Math.round((8 * 3 * i) - 3);
                        const y = Math.round((8 * 3 / 2) - 3);
                        const thickness = Number(props.scale(value));

                        return (
                            <g className="map-legend__flow" transform={ `translate(0, ${transformY})` }>
                                <path
                                    d={ `M0,${ y }L80,${ y - thickness }L80,${ y + thickness }Z` }
                                    fill="url(#gradient-legend)"
                                />
                                <line x1="0" y1={ y } x2="80" y2={ y } marker-end="url(#arrowhead-legend)" />
                                <text x="84" y={ y } dy="0.35em">{ props.formatter(value) }</text>
                            </g>
                        );
                    })
                }
            </svg>
        </div>
    );
}
