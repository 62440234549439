import React, { Component } from 'react';

import { Filters, FiltersItem } from '../Filters';
import QuerySelect from '../query-select/query-select';
import QueryTranspose from '../query-transpose/query-transpose';
import Checkbox from '../Checkbox';

export default class QueryDesktop extends Component {
  render(props) {
    let exporterInvalid = props.query.importer;
    if (
      props.query.importer.children &&
      props.query.importer.children.length &&
      !props.query.importer.model.aggregate
    ) {
      exporterInvalid = null;
    }

    let importerInvalid = props.query.exporter;
    if (
      props.query.exporter.children &&
      props.query.exporter.children.length &&
      !props.query.exporter.model.aggregate
    ) {
      importerInvalid = null;
    }

    return (
      <Filters>
        <FiltersItem>
          <QuerySelect
            id="exporter"
            label="Exporter"
            current={props.query.exporter}
            disabled={props.disabledCountries}
            invalid={[exporterInvalid]}
            data={[
              { name: 'Countries', data: props.data.countries },
              {
                name: 'Regions',
                data: props.data.countryGroupsRegions,
                showCounts: true,
                active: props.query.exporter && props.query.exporter.model.type === 'region',
              },
              {
                name: 'Groups',
                data: props.data.countryGroupsMain,
                showCounts: true,
                active: props.query.exporter && props.query.exporter.model.type === 'group',
              },
            ]}
            default={props.data.countries.getDefault()}
            searchable
            handleSelect={this.handleSelect.bind(this, 'exporter')}
            handlePopoverToggle={props.handlePopoverToggle}
          />
        </FiltersItem>
        <FiltersItem>
          <QuerySelect
            id="importer"
            label="Importer"
            current={props.query.importer}
            disabled={props.disabledCountries}
            invalid={[importerInvalid]}
            data={[
              { name: 'Countries', data: props.data.countries },
              {
                name: 'Regions',
                data: props.data.countryGroupsRegions,
                showCounts: true,
                active: props.query.importer && props.query.importer.model.type === 'region',
              },
              {
                name: 'Groups',
                data: props.data.countryGroupsMain,
                showCounts: true,
                active: props.query.importer && props.query.importer.model.type === 'group',
              },
            ]}
            default={props.data.countries.getDefault()}
            searchable
            handleSelect={this.handleSelect.bind(this, 'importer')}
            handlePopoverToggle={props.handlePopoverToggle}
          />
          <QueryTranspose handleClick={this.handleTranspose} tooltip="Swap exporter/importer" />
        </FiltersItem>
        <FiltersItem>
          <QuerySelect
            id="category"
            label="Commodity"
            current={props.query.category}
            data={[{ name: 'Commodities', data: props.data.categories }]}
            default={props.data.categories.getDefault()}
            searchable
            handleSelect={this.handleSelect.bind(this, 'category')}
            handlePopoverToggle={props.handlePopoverToggle}
          />
        </FiltersItem>
        <FiltersItem>
          <QuerySelect
            label="Year"
            current={props.query.year}
            disabled={props.disabledYears}
            data={props.data.years}
            reverse={true}
            handleSelect={this.handleSelect.bind(this, 'year')}
            handlePopoverToggle={props.handlePopoverToggle}
          />
        </FiltersItem>
        <FiltersItem>
          <Checkbox checked={props.autoZoom} onChange={this.handleChangeAutoZoom}>
            {__('trade-filter-zoom')}
          </Checkbox>
        </FiltersItem>
      </Filters>
    );
  }

  handleSelect(id, node) {
    this.props.handleSelect(id, node);
  }

  handleTranspose = () => {
    this.props.handleTranspose();
  };

  handleChangeAutoZoom = (event) => {
    this.props.handleChangeAutoZoom(event.target.checked);
  };
}
