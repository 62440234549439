import React from 'react';

import './_radio.scss';

const Radio = (props) => {
    return (
        <label className="radio">
            <input
                className="radio__input"
                type="radio"
                name={ props.name }
                value={ props.value }
                checked={ props.value === props.current ? true : null }
            />
            <span className="radio__label">
                <span className="radio__label__icon" />
                { props.label }
            </span>
        </label>
    );
}

export default Radio;
