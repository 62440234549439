'use strict';

import React from 'react';

const DownloadTrendTitle = (props) => {
    return (
        <span title="Compound annual growth rate (CAGR)">
            {
                props.years ?
                `${props.years[0]}–${props.years[1]}` :
                '2011–2015'
            }
        </span>
    );
}

export default DownloadTrendTitle;
