import React from 'react';

import Icon from '../icon/icon.jsx';

import './styles.scss';

export const Checkbox = ({ children, checked, onChange }) => {
  return (
    <label className="checkbox">
      <input className="checkbox__input" type="checkbox" checked={checked} onChange={onChange} />
      <span className="checkbox__icon">
        <Icon id="check" />
        <Icon id="check-on" />
      </span>
      <span className="checkbox__label">{children}</span>
    </label>
  );
};

export default Checkbox;
