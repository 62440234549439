import React, { Component } from 'react';

import { compareTrades } from '../../helpers';

import './_map-flows.scss';

export default class MapFlows extends Component {
    constructor () {
        super();
    }

    render (props) {
        let flows = [];
        let arrows = [];
        let activeFlows = [];
        let activeArrows = [];

        props.data.forEach(function (datum) {
            let id = `${datum.exporter.id}-${datum.importer.id}`;

            let flow = (
                <use
                    className="map__flow__outline"
                    xlinkHref={ `#flow-${id}` }
                    fill={ `url(#gradient-${id})` }
                />
            );

            let arrow = (
                <use className="map__flow__arrow" xlinkHref={ `#arrow-${id}` } />
            );

            if (props.hoveredFlow &&
                compareTrades(props.hoveredFlow, datum.original))
            {
                activeFlows.push(flow);
                activeArrows.push(arrow);
                return;
            }

            if (props.hoveredCountry || props.activeCountry) {
                let tempCountry = props.hoveredCountry || props.activeCountry;

                if (tempCountry === datum.original.exporter ||
                    tempCountry === datum.original.importer)
                {
                    activeFlows.push(flow);
                    activeArrows.push(arrow);
                    return;
                }
            }

            flows.push(flow);
            arrows.push(arrow);
        }, this);

        let className = 'map__flows';
        if (activeFlows.length) {
            className += ' map__flows--hovered';
        }

        return (
            <g class={ className }>
                <g className="map__flows__main">
                    { flows }
                    { arrows }
                </g>
                { activeFlows }
                { activeArrows }
            </g>
        )
    }
}
