import React from 'react';

import DonutChart from '../donutchart/donutchart';

import formatters from '../../formatters';

export default function TradeDonutChart(props) {
  if (!props.data) {
    return;
  }

  const propName = `pc_${props.query.units}`;
  const datum = props.data.filter(function (datum) {
    return datum.year.id === props.query.year.id;
  });
  const value = datum[0] && datum[0][propName] ? datum[0][propName] : null;

  return (
    <div className="trade-donut-chart">
      <DonutChart value={value} formattedValue={formatters.percentage(value)} title={props.title} />
    </div>
  );
}
