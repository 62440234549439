import React from 'react';
import clsx from 'clsx';
import { scaleLinear, scalePoint } from 'd3-scale';
import { line } from 'd3-shape';
import range from 'lodash/range';

import './styles.scss';

export default function Sparkline({ data, type, width = 36 + 2, height = 16 + 2 }) {
  let viewbox = { width: width, height: height };

  let scaleX = scalePoint()
    .domain(range(0, data.length))
    .range([1, viewbox.width - 1]);

  let min = Math.min.apply(null, data);
  let max = Math.max.apply(null, data);

  let scaleY = scaleLinear()
    .domain([min, max])
    .range([viewbox.height - 1, 1]);

  let lineGenerator = line()
    .x(function (datum, i) {
      return scaleX(i);
    })
    .y(function (datum) {
      return scaleY(datum);
    });

  let path = lineGenerator(data);

  return (
    <svg
      class={clsx('sparkline', type && `sparkline--${type}`)}
      viewBox={`0 0 ${viewbox.width} ${viewbox.height}`}
      preserveAspectRatio="none"
    >
      <path d={path} />
    </svg>
  );
}
