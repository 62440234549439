import React, { Component } from 'react';
import { scaleLinear, scalePoint } from 'd3-scale';
import { line } from 'd3-shape';
import debounce from 'lodash/debounce';

import './_linechart.scss';

export default class LineChart extends Component {
    constructor (props) {
        super();

        this.state = {
            width: 0,
            height: 0,
        };

        this.windowWidth = null;
        this._handleWindowResize = debounce(this.handleWindowResize, 200);
    }

    componentDidMount () {
        window.addEventListener('resize', this._handleWindowResize);

        if (!this.state.width || !this.state.height) {
            this.updateDimensions();
        }
    }

    componentWillUnmount () {
        window.removeEventListener('resize', this._handleWindowResize);
    }

    render (props, state) {
        if (!state.width || !state.height) {
            return this.renderStub();
        }

        let scaleX = scalePoint()
            .domain(props.years)
            .range([0, state.width]);

        let markerScaleX = scaleX.copy()
            .range([0, 100]);

        let max;

        if (props.max) {
            max = props.max;
        } else {
            max = props.data.reduce(function (memo, datum) {
                return Math.max(memo, datum.value);
            }, 0);
        }

        let scaleY = scaleLinear()
            .domain([0, max])
            .range([state.height, 0])
            .nice();

        let lineGenerator = line()
            .x(function (datum) {
                return scaleX(datum.year);
            })
            .y(function (datum) {
                return scaleY(datum.value);
            })
            .defined(function (datum) {
                return (datum.value !== null);
            });

        let hoveredYearMarker;
        if (props.hoveredYear) {
            hoveredYearMarker = this.renderMarker(markerScaleX(props.hoveredYear), 'hover');
        }

        return (
            <div className="linechart">
                <div className="linechart__body">
                    <div className="linechart__canvaswrapper">
                        <svg
                            ref={ (node) => { this.refCanvas = node } }
                            className="linechart__canvas"
                            viewBox={ `0 0 ${state.width} ${state.height}` }
                            preserveAspectRatio="none"
                        >
                            <path d={ lineGenerator(props.data) } />
                            <g>
                                { this.renderMissingDataPoints(props.data, scaleX, scaleY) }
                            </g>
                        </svg>
                    </div>
                    <div className="linechart__xaxis">
                        <div className="linechart__xaxis__ticks">
                            { this.renderXAxis(props.data, markerScaleX, props.xAxisInterval) }
                        </div>
                    </div>
                    <div className="linechart__yaxis">
                        { this.renderYAxis(props.data, scaleY, props.formatter) }
                    </div>
                    <div className="linechart__markers">
                        { hoveredYearMarker }
                        { this.renderMarker(markerScaleX(props.year)) }
                    </div>
                    <div
                        className="linechart__hitboxes"
                        onTouchMove={ this.handleTouchMove }
                        onTouchEnd={ this.handleTouchEnd }
                        onTouchCancel={ this.handleTouchEnd }
                    >
                        { this.renderHitboxes(props.data, markerScaleX) }
                    </div>
                </div>
            </div>
        );
    }

    renderStub () {
        return (
            <div className="linechart">
                <div className="linechart__body">
                    <div className="linechart__canvaswrapper">
                        <svg
                            ref={ (node) => { this.refCanvas = node } }
                            className="linechart__canvas"
                        />
                    </div>
                    <div className="linechart__xaxis" />
                    <div className="linechart__yaxis" />
                    <div className="linechart__markers" />
                    <div className="linechart__hitboxes" />
                </div>
            </div>
        );
    }

    renderMarker (x, type) {
        let className = 'linechart__marker';
        if (type) {
            className += ` linechart__marker--${type}`;
        }

        return (
            <div
                class={ className }
                style={{ left: `${x}%` }}
            >
                <svg viewBox="0 0 9 4">
                    <path d="M4,0,0,4H9L5,0Z" />
                </svg>
            </div>
        );
    }

    renderXAxis (data, scale, interval) {
        return data.reduce(function (memo, datum, i) {
            const mod = datum.year % interval;
            const draw = !mod || (i === data.length - 1 && mod > 2);

            if (!draw) {
                return memo;
            }

            let x = scale(datum.year);

            memo.push(
                <div className="linechart__xaxis__tick" style={{ left: `${x}%` }}>
                    <span>{ datum.year }</span>
                </div>
            );

            return memo;
        }, []);
    }

    renderYAxis (data, scale, formatter) {
        let max = scale.domain()[1];

        return (
            <div>
                <div className="linechart__yaxis__tick">
                    <span>{ formatter(max) }</span>
                </div>
                <div className="linechart__yaxis__tick" style={{ top: '25%' }}>
                    <span>{ formatter(max * 0.75) }</span>
                </div>
                <div className="linechart__yaxis__tick" style={{ top: '50%' }}>
                    <span>{ formatter(max * 0.5) }</span>
                </div>
                <div className="linechart__yaxis__tick" style={{ top: '75%' }}>
                    <span>{ formatter(max * 0.25) }</span>
                </div>
                <div className="linechart__yaxis__tick" style={{ top: '100%' }}>
                    <span>{ formatter(0) }</span>
                </div>
            </div>
        );
    }

    renderMissingDataPoints (data, scaleX, scaleY) {
        return data.reduce(function (memo, datum, i) {
            if (!datum) {
                return memo;
            }

            let value = datum.value;
            let nextValue = (i < data.length - 1) ? data[i + 1].value : null;
            let prevValue = (i > 0) ? data[i - 1].value : null;

            if (value !== null && prevValue === null && nextValue === null) {
                memo.push(<circle cx={ scaleX(datum.year) } cy={ scaleY(value) } />);
            }

            return memo;
        }, []);
    }

    renderHitboxes (data, scaleX) {
        let width = 100 / data.length;

        return data.map(function (datum) {
            return (
                <div
                    className="linechart__hitbox"
                    title={ datum.year }
                    style={{
                        left: `${scaleX(datum.year)}%`,
                        width: `${width}%`,
                        marginLeft: `${width / -2}%`,
                    }}
                    data-id={ datum.year }
                    onMouseEnter={ this.handleMouseEnter.bind(this, datum.year) }
                    onMouseLeave={ this.handleMouseLeave }
                    onClick={ this.handleClick.bind(this, datum.year) }
                />
            );
        }, this);
    }

    handleMouseEnter = (year) => {
        if (this.props.onHover) {
            this.props.onHover(year);
        }
    }

    handleMouseLeave = () => {
        if (this.props.onHover) {
            this.props.onHover(null);
        }
    }

    handleTouchMove = (event) => {
        if (!this.props.onHover || event.touches.length !== 1) {
            return;
        }

        const node = document.elementFromPoint(event.touches[0].clientX, event.touches[0].clientY);

        if (!node) {
            return;
        }

        const year = node.getAttribute('data-id');

        this.props.onHover(year);
    }

    handleTouchEnd = () => {
        if (this.props.onHover) {
            this.props.onHover(null);
        }
    }

    handleClick = (year) => {
        if (this.props.onClick) {
            this.props.onClick(year);
        }
    }

    handleWindowResize = () => {
        const windowWidth = document.body.clientWidth;

        if (this.windowWidth && this.windowWidth === windowWidth) {
            return;
        }

        this.updateDimensions();

        this.windowWidth = windowWidth;
    }

    updateDimensions = () => {
        // Skip if component is not in DOM
        if (!this.base || !this.base.offsetParent || !this.refCanvas) {
            return;
        }

        const rect = this.refCanvas.getBoundingClientRect();

        this.setState({
            width: rect.width,
            height: rect.height,
        });
    }
}
