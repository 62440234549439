import React from 'react';

import './styles.scss';

export default function MiniMapLegend() {

  const SVG_WIDTH = 56;
  const SVG_HEIGHT = 11;

  return (
    <div className="mini-map-legend" aria-hidden="true">
      <svg className="mini-map-legend__graphic" width={56} height={11}>
        <defs>
          <linearGradient
            id="mini-map-legend-gradient"
            x1="0"
            y1="0"
            x2="1"
            y2="0"
            spreadMethod="pad"
          >
            <stop offset="25%" className="mini-map-legend__exports" />
            <stop offset="100%" className="mini-map-legend__imports" />
          </linearGradient>
          <marker
            id="arrowhead-legend"
            viewBox="0 0 10 10"
            refX="10"
            refY="5"
            markerWidth="5"
            markerHeight="5"
            orient="auto"
          >
            <path d="M0,0L10,5L0,10" className="mini-map__arrowhead"></path>
          </marker>
        </defs>
        <rect x={0} y={0} width={SVG_WIDTH} height={SVG_HEIGHT} fill="url(#mini-map-legend-gradient)" />
        <line className="mini-map-legend__arrow" x1="0" y1={ SVG_HEIGHT / 2 } x2="56" y2={ SVG_HEIGHT / 2 } marker-end="url(#arrowhead-legend)" />
      </svg>
      <p className="mini-map-legend__labels">
        <span>Exp.</span>
        <span>Imp.</span>
      </p>
    </div>
  );
}
