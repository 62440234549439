import React, { Component } from 'react';

import DonutChart from '../donutchart/donutchart';

import formatters from '../../formatters';

import './styles.scss';

export default function TradeReadout(props) {
  const formatter = props.query.units === 'value' ? formatters.currency : formatters.weight;

  const value = props.data[props.query.units];

  let exporterPc;
  let importerPc;
  if (props.totals.status === 'ready') {
    const totals = props.totals.data.main;
    exporterPc = value / totals[props.data.exporter.id][props.query.units].exports;
    importerPc = value / totals[props.data.importer.id][props.query.units].imports;
  } else {
    exporterPc = null;
    importerPc = null;
  }

  return (
    <div className="trade-readout">
      <p className="trade-readout__category">{props.query.category.model.name}</p>
      <p className="trade-readout__countries">
        <span>{props.data.exporter.model.name}</span>
        <span>{props.data.importer.model.name}</span>
      </p>
      <div className="trade-readout__arrow" />
      <div className="trade-readout__charts">
        <div className="trade-readout__charts__chart">
          <DonutChart
            value={exporterPc}
            formattedValue={formatters.percentage(exporterPc)}
            type="exporter"
          />
          <p>of exports</p>
        </div>
        <div className="trade-readout__charts__value">
          <p>{formatter(value)}</p>
        </div>
        <div className="trade-readout__charts__chart">
          <DonutChart
            value={importerPc}
            formattedValue={formatters.percentage(importerPc)}
            type="importer"
          />
          <p>of imports</p>
        </div>
      </div>
      <div
        className="trade-readout__list"
        aria-hidden={
          !props.data.env_co2_valid && !props.data.env_land_valid && !props.data.env_water_valid
            ? 'true'
            : null
        }
      >
        <h2>Environmental Footprint</h2>
        <ul>
          <li aria-hidden={!props.data.env_co2_valid ? 'true' : null}>
            <span>Carbon dioxide</span>
            <span>{formatters.weight(props.data.env_co2)}</span>
          </li>
          <li aria-hidden={!props.data.env_land_valid ? 'true' : null}>
            <span>Land</span>
            <span>{formatters.tens(props.data.env_land, 'ha')}</span>
          </li>
          <li aria-hidden={!props.data.env_water_valid ? 'true' : null}>
            <span>Blue water</span>
            <span>{formatters.tens(props.data.env_water, 'm³')}</span>
          </li>
        </ul>
      </div>
    </div>
  );
}
