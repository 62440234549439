import React, { Component } from 'react';
import sortBy from 'lodash/sortBy';
import keyBy from 'lodash/keyBy';

import LineChart from '../../components/linechart/linechart';
import LineChartReadout from '../../components/LineChartReadout';
import LineChartHeader from '../../components/LineChartHeader';

import formatters from '../../formatters';

// FIXME: Remove dependency on store
import store from '../../store';

export default class DashboardChart extends Component {
    constructor () {
        super();

        this.state = {
            hoveredYear: null
        };
    }

    shouldComponentUpdate (nextProps, nextState) {
        let dirty = (
            this.props.data !== nextProps.data ||
            this.props.query !== nextProps.query ||
            this.props.years !== nextProps.years ||
            this.state.hoveredYear !== nextState.hoveredYear
        );

        return (nextProps.data !== null && dirty);
    }

    render (props, state) {
        if (props.data === null) {
            return;
        }

        let propName = props.query.units;
        let formatter = (props.query.units === 'value') ? formatters.currency : formatters.weight;

        if (props.formatter === 'pc') {
            propName = `pc_${props.query.units}`;
            formatter = formatters.percentage;
        }

        let yearData = keyBy(props.data, function (datum) {
            return datum.year.id;
        });

        let headerYear = state.hoveredYear ? state.hoveredYear : props.query.year.id;
        let headerYearData = yearData[headerYear] || {};

        let years = [];

        let data = props.years.map(function (year) {
            let datum = yearData[year.id];

            years.push(year.id);

            return {
                year: year.id,
                value: datum ? datum[propName] : null,
                original: datum
            };
        });

        data = sortBy(data, ['year']);

        return (
            <>
                <LineChartHeader text={props.title} />
                <LineChartReadout
                    value={formatter(headerYearData[propName])}
                />
                <LineChart
                    data={ data }
                    year={ props.query.year.id }
                    hoveredYear={ state.hoveredYear }
                    years={ years.sort() }
                    xAxisInterval="5"
                    formatter={ formatter }
                    max={ props.formatter === 'pc' ? 1 : null }
                    onHover={ this.handleHover }
                    onClick={ this.handleClick }
                />
            </>
        );
    }

    handleHover = (year) => {
        this.setState({
            hoveredYear: year
        });
    }

    handleClick = (year) => {
        store.dispatch('SET_YEAR', year);
    }
}
