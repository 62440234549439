import React, { Component } from 'react';
import { scaleLinear } from 'd3-scale';
import { arc } from 'd3-shape';

import './_donutchart.scss';

let scale = scaleLinear()
    .domain([0, 1])
    .range([
        (Math.PI * 1.2),
        (Math.PI * 2.8)
    ]);

let donut = arc()
    .innerRadius(50 - (12 / 68 * 100))
    .outerRadius(50)
    .startAngle(scale(0))
    .endAngle(function (d) {
        return scale(d);
    });

let outline = arc()
    .innerRadius(50)
    .outerRadius(50)
    .startAngle(scale(0))
    .endAngle(scale(1))

export default class DonutChart extends Component {
    shouldComponentUpdate (nextProps) {
        return (this.props.value !== nextProps.value);
    }

    render (props) {
        let className = 'donutchart';
        // TODO: Change 'type' to 'kind'
        if (props.type) {
            className += ' donutchart--' + props.type;
        }

        return (
            <div class={ className }>
                <div className="donutchart__canvas">
                    <svg viewBox="0 0 100 100" preserveAspectRatio="none">
                        <g transform="translate(50, 50)">
                            <path className="donutchart__outline" d={ outline() } />
                            <path className="donutchart__fill" d={ donut(props.value) } />
                        </g>
                    </svg>
                    <p className="donutchart__value">{ props.formattedValue }</p>
                </div>
                {
                    props.title ?
                    <p className="donutchart__title">{ props.title }</p> :
                    null
                }
            </div>
        );
    }
}
