import React from 'react';
import clsx from 'clsx';

import IconButton from '../icon-button/icon-button.jsx';

export default function DownloadButton({ state, onClick }) {
  return (
    <div class="download-button" aria-live="assertive">
      <IconButton
        kind="rounded"
        icon={state === 'pending' ? 'loader' : 'download'}
        disabled={state === 'pending' ? true : null}
        tabIndex={0}
        ariaLabel={state === 'pending' ? 'Download pending…' : 'Download data'}
        handleClick={onClick}
      />
    </div>
  );
}
