import React from 'react';

import InfoModal from '../InfoModal';

export default function NesInfo() {
  return (
    <InfoModal header={<h1>Areas, nes and Special Categories</h1>}>
      <p>
        <strong>Areas, NES</strong> (not elsewhere specified) are unknown trading partners, recorded
        when the reporting country does not submit the details of their partner. This occurs (a) for
        low value trades and (b) if the partner designation is unknown to the reporting country, or
        if an error is made in assigning the trading partner. Reporting countries sometimes withhold
        partner details to protect company information. Different categories of areas not elsewhere
        specified are associated with the world as a whole, with continents, and (prior to 2006)
        with regions.
      </p>
      <p>
        <strong>Special Categories</strong> is used by countries reporting trade flows if they do
        not want the trading partner breakdown to be disclosed.
      </p>
      <p>
        The distinction between an “unknown partner country” and an “undisclosed partner country”
        can sometimes be mixed up when recorded.
      </p>
    </InfoModal>
  );
}
